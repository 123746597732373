import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { ALL_USER_PROFILE } from '../../constants';
import { UserProfile } from '../../types';

const JOIN_TEAM_INVITE_MUTATION = gql`
  mutation JoinTeam($input: JoinTeamInput!) {
    joinTeam(input: $input) {
      success
      code
      userProfile {
        ${ALL_USER_PROFILE}
      }
    }
  }
`;

export type JoinTeamResponse = {
  joinTeam: {
    success: boolean;
    userProfile?: UserProfile;
  };
};

type JoinTeamOperationVariables = {
  input: {
    accessCode: string;
  };
};

export function useJoinTeam() {
  const [execute, result] = useMutation<
    JoinTeamResponse,
    JoinTeamOperationVariables
  >(JOIN_TEAM_INVITE_MUTATION);

  const createVariables = useCallback<
    (a: JoinTeamOperationVariables['input']) => any
  >(
    ({ accessCode }) => ({
      variables: { input: { accessCode } },
    }),
    []
  );

  return {
    execute,
    createVariables,
    ...result,
  };
}

export default useJoinTeam;
