class TypeGuard<T> {
  // @ts-ignore
  private as: T;
}

/** T is the type that you store/retrieve from localstorage.
 *
 * Usage:
 *
 * const mykey = "mykey" as LocalStorageKey\<boolean\>;
 *
 * LocalStorage.get(mykey) -> boolean
 *
 * LocalStorage.set(mykey, "not a boolean") -> error
 */
export type LocalStorageKey<T> = TypeGuard<T> & string;

export class LocalStorage {
  private constructor() {}
  /**
   * Sets the value of the pair identified by key to value, creating a new key/value pair if none existed for key previously.
   *
   * Throws a "QuotaExceededError" DOMException exception if the new value couldn't be set. (Setting could fail if, e.g., the user has disabled storage for the site, or if the quota has been exceeded.)
   */
  public static setItem<T>(key: LocalStorageKey<T>, value: T) {
    return localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Returns the current value associated with the given key, or null if the given key does not exist in the list associated with the object.
   */
  public static getItem<T>(key: LocalStorageKey<T>): T | null {
    const local: string | null = localStorage.getItem(key);
    if (typeof local === 'string') {
      return JSON.parse(local) as T;
    }
    return local;
  }

  /**
   * Removes the key/value pair with the given key from the list associated with the object, if a key/value pair with the given key exists.
   */
  public static removeItem(key: LocalStorageKey<any>): void {
    return localStorage.removeItem(key);
  }

  /**
   * Empties the list associated with the object of all key/value pairs, if there are any.
   */
  public static clear(): void {
    return localStorage.clear();
  }

  /**
   * Returns the name of the nth key in the list, or null if n is greater than or equal to the number of key/value pairs in the object.
   */
  public static key<T = unknown>(index: number): LocalStorageKey<T> | null {
    return localStorage.key(index) as LocalStorageKey<T>;
  }

  /**
   * Returns the number of key/value pairs currently present in the list associated with the object.
   */
  public static size(): number {
    return localStorage.length;
  }
}
