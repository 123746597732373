import { v4 as uuidv4 } from 'uuid';
import StatusCodes from 'http-status-codes';
import axios from 'axios';
import { getCurrentUserAgent } from '../utils/UserInfo';

enum LogSeverity {
  default = 'DEFAULT',
  debug = 'DEBUG',
  info = 'INFO',
  notice = 'NOTICE',
  warning = 'WARNING',
  error = 'ERROR',
  critical = 'CRITICAL',
  alert = 'ALERT',
  emergency = 'EMERGENCY',
}

export interface LogEntry extends Record<string, unknown> {
  id: string;
  level: LogSeverity; //ref: https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry#logseverity
  message: string;
  context: LogContext;
  error?: LogError;
}

export interface LogContext extends Record<string, unknown> {
  metric: LogMetric;
  deviceDetails: DeviceDetails;
}

export interface LogMetric extends Record<string, unknown> {
  name: string;
  source: string;
  value: string;
  logTime: number;
}

export interface DeviceDetails extends Record<string, unknown> {
  ua: string;
  browser: string;
  device: string;
  operatingSystem: string;
  ip: string;
}

export interface LogError extends Record<string, unknown> {
  key: string;
  message?: string;
  stackTrace?: string;
}

export class Logger {
  private deviceDetails: DeviceDetails | undefined = undefined;

  private constructor() {}

  public static getInstance = async (): Promise<Logger> => {
    const logger = new Logger();
    const userAgent = await getCurrentUserAgent();
    logger.deviceDetails = {
      ua: userAgent.ua || '',
      browser: userAgent.browser || '',
      device: userAgent.device || '',
      operatingSystem: userAgent.operatingSystem || '',
      ip: userAgent.ip || '',
    };
    return logger;
  };

  async log(
    message: string,
    metric: LogMetric,
    level: LogSeverity = LogSeverity.default,
    error: LogError | undefined = undefined
  ): Promise<boolean> {
    const context: LogContext = {
      metric,
      deviceDetails: this.deviceDetails!,
    };
    const data: LogEntry = {
      id: uuidv4(),
      level,
      message,
      context,
      error,
    };
    return await this.postLogEntry([data]);
  }

  async error(
    message: string,
    metric: LogMetric,
    error: LogError | undefined = undefined
  ): Promise<boolean> {
    return this.log(message, metric, LogSeverity.error, error);
  }

  private postLogEntry = async (data: LogEntry[]): Promise<boolean> => {
    return axios({
      method: 'post',
      url: process.env.REACT_APP_LOGGING_URL,
      data,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === StatusCodes.OK) {
          return true;
        }
        console.log(
          `Log entry post failed with data: ${JSON.stringify(res.data)}`
        );
        return true;
      })
      .catch((err) => {
        console.log(`Log entry post failed with error: ${JSON.stringify(err)}`);
        return false;
      });
  };
}
