import { useCallback } from 'react';
import { gql, useQuery, useLazyQuery, QueryLazyOptions } from '@apollo/client';
import { CompanyCatalogMetrics } from '../../types';

const GET_COMPANY_CATALOG_METRICS = gql`
  query GetCompanyCatalogMetrics($companyId: String!, $catalogId: String!) {
    companyCatalogMetrics(companyId: $companyId, catalogId: $catalogId) {
      pushStat {
        count {
          monthly
          weekly
          daily
        }
        rateOfChange {
          monthly
          weekly
          daily
        }
      }
      generationDateEpoch
    }
  }
`;

type GetCompanyCatalogMetricsResponse = {
  companyCatalogMetrics: CompanyCatalogMetrics;
};
type GetCompanyCatalogMetricsOperationVariables = {
  companyId: string;
  catalogId: string;
};
type GetCompanyCatalogMetricsRequest =
  GetCompanyCatalogMetricsOperationVariables;

export function useGetCompanyCatalogMetrics({
  companyId,
  catalogId,
}: GetCompanyCatalogMetricsRequest) {
  return useQuery<
    GetCompanyCatalogMetricsResponse,
    GetCompanyCatalogMetricsOperationVariables
  >(GET_COMPANY_CATALOG_METRICS, {
    variables: { companyId, catalogId },
  });
}

export function useLazyGetCompanyCatalogMetrics() {
  const [execute, result] = useLazyQuery<
    GetCompanyCatalogMetricsResponse,
    GetCompanyCatalogMetricsOperationVariables
  >(GET_COMPANY_CATALOG_METRICS);

  const createVariables = useCallback<
    (
      a: GetCompanyCatalogMetricsOperationVariables
    ) => QueryLazyOptions<GetCompanyCatalogMetricsOperationVariables>
  >(
    ({ companyId, catalogId }) => ({
      variables: { companyId, catalogId },
    }),
    []
  );

  return {
    execute,
    createVariables,
    ...result,
  };
}
