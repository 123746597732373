export enum AdsServiceMessageErrorCodes {
  AdMutationWebrisk = 'AD_MUTATE_WEBRISK',
}

export class AdsServiceError extends Error {
  constructor(message: string = '') {
    super(message);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AdsServiceError);
    }
    this.name = 'AdsServiceError';
    Object.setPrototypeOf(this, AdsServiceError.prototype);
  }
}
