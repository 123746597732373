export enum UserProfileServiceGraphqlErrorCodes {
  ProfileNotFound = 'PROFILE_NOT_FOUND',
}
export enum UserProfileServiceErrorCodes {
  ProfileMutateWebrisk = 'PROFILE_MUTATE_WEBRISK',
}

export class UserProfileNotFoundError extends Error {
  constructor() {
    super('User not found.');
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UserProfileNotFoundError);
    }
    this.name = 'UserProfileNotFoundError';
    Object.setPrototypeOf(this, UserProfileNotFoundError.prototype);
  }
}

export class UserProfileServiceError extends Error {
  constructor(message: string = '') {
    super(message);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UserProfileServiceError);
    }
    this.name = 'UserProfileServiceError';
    Object.setPrototypeOf(this, UserProfileServiceError.prototype);
  }
}

export class CompanyServiceError extends Error {
  constructor(message: string = '') {
    super(message);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UserProfileServiceError);
    }
    this.name = 'CompanyServiceError';
    Object.setPrototypeOf(this, CompanyServiceError.prototype);
  }
}
