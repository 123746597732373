import { combineReducers } from 'redux';
import * as actions from './billing.actions';
import * as authActions from '../auth/auth.actions';
import { createReducer } from 'deox';
import { Types } from '../../api';
import {
  BillingApiRequestTypes,
  BillingApiRequests,
} from './billing.constants';
import { ApiCallStatus } from '../shared/shared.constants';
import produce from 'immer';
import { AuthApiRequestTypes } from '../auth/auth.constants';

const NULL_BILLING_ACCOUNT: Types.BillingAccount = {
  __typename: 'BillingAccount',
  cards: [],
  userId: '',
  accountStatus: Types.BillingAccountStatus.Unknown,
};

const billingAccount = createReducer(NULL_BILLING_ACCOUNT, (handleAction) => [
  handleAction(
    [actions.fetchBillingSuccess, actions.createBillingSuccess],
    (_, { payload }) =>
      payload.__typename === 'BillingAccountNotFound'
        ? NULL_BILLING_ACCOUNT
        : payload
  ),
  handleAction(
    [actions.fetchBillingFailure, actions.createBillingFailure],
    () => NULL_BILLING_ACCOUNT
  ),
  handleAction(authActions.setApiStatus, (prevState, { payload }) =>
    produce(prevState, () => {
      if (
        payload.requestType === AuthApiRequestTypes.DeleteAccount ||
        payload.requestType === AuthApiRequestTypes.LogOut
      ) {
        if (payload.update.status === ApiCallStatus.Success) {
          return NULL_BILLING_ACCOUNT;
        }
      }
    })
  ),
  handleAction(actions.setApiStatus, (prevState, { payload }) =>
    produce(prevState, () => {
      if (payload.requestType === BillingApiRequestTypes.CreateBillingAccount) {
        if (payload.update.status === ApiCallStatus.Success) {
          return (
            payload.update as BillingApiRequests[BillingApiRequestTypes.CreateBillingAccount]
          ).billingAccount!!;
        } else if (payload.update.status === ApiCallStatus.Error) {
          return NULL_BILLING_ACCOUNT;
        }
      }
    })
  ),
  handleAction(actions.updateBilling, (prevState, { payload }) =>
    produce(prevState, (draft) => {
      if (payload.cards) {
        draft.cards = payload.cards;
      }
      if (payload.primaryCardId) {
        draft.primaryCardId = payload.primaryCardId;
      }
      if (payload.accountStatus) {
        draft.accountStatus = payload.accountStatus;
      }
    })
  ),
]);

const DEFAULT_API_REQUESTS: BillingApiRequests = {
  [BillingApiRequestTypes.CreateBillingAccount]: {
    status: ApiCallStatus.Idle,
  },
  [BillingApiRequestTypes.AddCard]: {
    status: ApiCallStatus.Idle,
  },
  [BillingApiRequestTypes.RemoveCard]: {
    status: ApiCallStatus.Idle,
  },
  [BillingApiRequestTypes.EditCard]: {
    status: ApiCallStatus.Idle,
  },
  [BillingApiRequestTypes.ReplaceCard]: {
    status: ApiCallStatus.Idle,
  },
  [BillingApiRequestTypes.SetPrimaryCardId]: {
    status: ApiCallStatus.Idle,
  },
};

const apiRequests = createReducer(DEFAULT_API_REQUESTS, (handleAction) => [
  handleAction(actions.setApiStatus, (prevState, { payload }) =>
    produce(prevState, (draftState: BillingApiRequests) => {
      draftState[payload.requestType] = payload.update;
    })
  ),
]);

export default combineReducers({
  billingAccount,
  apiRequests,
});
