import styled from 'styled-components';
import { Theme } from '@material-ui/core/styles';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';

interface TextBulletProps {
  themeFontSizeNumber?: number;
  theme: Theme;
}
const TextBullet = styled(FiberManualRecord).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['themeFontSizeNumber'].includes(prop),
})<TextBulletProps>`
  font-size: ${({ theme, themeFontSizeNumber = 1 }) =>
    theme.spacing(themeFontSizeNumber) / 16}rem;
`;

export default TextBullet;
