import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

const JOIN_TEAM_INVITE_MUTATION = gql`
  mutation RemoveTeamMember($companyID: String!, $teamMemberUserID: String!) {
    removeTeamMember(
      companyID: $companyID
      teamMemberUserID: $teamMemberUserID
    ) {
      success
      code
      message
      userProfile {
        userId
        selectedCompany {
          teamMembers {
            userId
            email
            companyRoles
          }
        }
      }
    }
  }
`;

export type RemoveTeamMemberResponse = {
  removeTeamMember: {
    success: boolean;
    userProfile: {
      userId: string;
      selectedCompany: {
        teamMembers: { userId: string; email: string; companyRoles: [] }[];
      };
    } | null;
  };
};

type JoinTeamOperationVariables = {
  companyID: string;
  teamMemberUserID: string;
};

export function useRemoveTeamMember() {
  const [execute, result] = useMutation<
    RemoveTeamMemberResponse,
    JoinTeamOperationVariables
  >(JOIN_TEAM_INVITE_MUTATION);

  const createVariables = useCallback<(a: JoinTeamOperationVariables) => any>(
    ({ companyID, teamMemberUserID }) => ({
      variables: { companyID, teamMemberUserID },
    }),
    []
  );

  return {
    execute,
    createVariables,
    ...result,
  };
}

export default useRemoveTeamMember;
