import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { ALL_USER_PROFILE_MINUS_TEAM_MEMBERS } from '../../constants';
import { UserProfile } from '../../types';

const CREATE_USER_PROFILE_WITH_COMPANY_MUTATION = gql`
  mutation CreateUserProfileWithCompany($input: CreateUserProfileWithCompanyInput!) {
    createUserProfileWithCompany(input: $input) {
      success
      code
      message
      userProfile {
        ${ALL_USER_PROFILE_MINUS_TEAM_MEMBERS}
      }
    }
  }
`;

export type CreateUserProfileWithCompanyResponse = {
  createUserProfileWithCompany: {
    success: boolean;
    userProfile?: UserProfile;
  };
};

type CreateUserProfileWithCompanyOperationVariables = {
  input: {
    email: string;
    businessInfo: {
      companyName: string;
      businessAlias?: string;
      yearEstablished: number;
      industry: string;
      homepage?: string;
    };
  };
};

export function useCreateUserProfileWithCompany() {
  const [execute, result] = useMutation<
    CreateUserProfileWithCompanyResponse,
    CreateUserProfileWithCompanyOperationVariables
  >(CREATE_USER_PROFILE_WITH_COMPANY_MUTATION);

  const createVariables = useCallback<
    (a: CreateUserProfileWithCompanyOperationVariables['input']) => any
  >(
    ({ businessInfo, email }) => ({
      variables: { input: { email, businessInfo } },
    }),
    []
  );

  return {
    execute,
    createVariables,
    ...result,
  };
}

export default useCreateUserProfileWithCompany;
