import { gql, useQuery } from '@apollo/client';
import { TeamMemberUserProfile, UserProfileResult } from '../../types';

const GET_CATALOG_ACTIVE_ADS = gql`
  query GetCompanyTeamMembers {
    userProfile {
      __typename
      ... on UserProfile {
        selectedCompany {
          teamMembers {
            userId
            email
            companyRoles
          }
        }
      }
      ... on UserProfileNotFound {
        reason
      }
    }
  }
`;

type GetTeamMembersResponse = {
  userProfile: UserProfileResult;
};

type UseGetTeamMembersResponse = {
  loading: boolean;
  data: TeamMemberUserProfile[] | undefined;
};

export function useGetTeamMembers(): UseGetTeamMembersResponse {
  const { data, loading } = useQuery<GetTeamMembersResponse>(
    GET_CATALOG_ACTIVE_ADS,
    {
      fetchPolicy: 'cache-and-network',
    }
  );
  if (data) {
    return {
      data:
        data.userProfile.__typename === 'UserProfile'
          ? data.userProfile.selectedCompany!.teamMembers
          : undefined,
      loading,
    };
  } else {
    return { loading, data: undefined };
  }
}
