import React from 'react';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import EmptyState from '../EmptyStates/EmptyState';

import * as S from './AppErrorPage.styled';

const SUPPORT_EMAIL = 'support@swaymee.com';
const AppErrorTitle = "Oh no! Something's wrong here";
const AppErrorMessage = (
  <Typography>
    {
      'Unfortunately, something went wrong and we were unable to access what you requested on the website. We apologize for any inconvenience and will continue to fix things to better serve you.'
    }
    <br />
    <br />
    {'Please contact our suppoort team at '}
    <b>support@swaymee.com</b>
    {' if this issue continues.'}
  </Typography>
);

export default function AppErrorPage() {

	const contactSupportOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    window.location.href = `mailto:${SUPPORT_EMAIL}`;
  };

  return (
    <>
      <EmptyState
        title={AppErrorTitle}
        message={AppErrorMessage}
        icon={<S.AppErrorIcon />}
      >
				<S.ButtonContainer>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={contactSupportOnClick}
            children={'Contact Support'}
          />
        </S.ButtonContainer>
			</EmptyState>
    </>
  );
}
