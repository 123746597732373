import { NAME, AuthStatusUpdates, AuthApiRequests } from './auth.constants';
import { createAction } from 'deox';
import { FirebaseError } from 'firebase/app';

export const deleteAccount = createAction(`${NAME}/deleteAccount`);

export const logout = createAction(`${NAME}/logout`);
export const logInRequest = createAction(
  `${NAME}/logInRequest`,
  (resolve) => (payload: EmailPasswordAuthRequest) => resolve(payload)
);
export const logInSuccess = createAction(`${NAME}/logInSuccess`);
export const logInFailure = createAction(
  `${NAME}/logInFailure`,
  (resolve) => (payload: FirebaseError) => resolve(payload)
);

export const sendPasswordReset = createAction(
  `${NAME}/sendPasswordReset`,
  (resolve) => (email: string) => resolve(email)
);
export const sendPasswordResetSuccess = createAction(
  `${NAME}/sendPasswordResetSuccess`
);
export const sendPasswordResetFailure = createAction(
  `${NAME}/sendPasswordResetFailure`,
  (resolve) => (payload: FirebaseError) => resolve(payload)
);
export const changePasswordRequest = createAction(
  `${NAME}/changePasswordRequest`
);
export const changePasswordSuccess = createAction(
  `${NAME}/changePasswordSuccess`
);
export const changePasswordFailure = createAction(
  `${NAME}/changePasswordFailure`,
  (resolve) => (payload: FirebaseError) => resolve(payload)
);

interface EmailPasswordAuthRequest {
  email: string;
  password: string;
}

export const createAccountRequest = createAction(
  `${NAME}/createAccountRequest`,
  (resolve) => (payload: EmailPasswordAuthRequest) => resolve(payload)
);
export const createAccountSuccess = createAction(
  `${NAME}/createAccountSuccess`
);
export const createAccountFailure = createAction(
  `${NAME}/createAccountFailure`,
  (resolve) => (payload: FirebaseError) => resolve(payload)
);
export const authRedirectProcessingComplete = createAction(
  `${NAME}/authRedirectProcessingComplete`
);
export const authPreProcessingStart = createAction(
  `${NAME}/authPreProcessingStart`
);
export const authPreProcessingComplete = createAction(
  `${NAME}/authPreProcessingComplete`
);

export const setApiStatus = createAction(
  `${NAME}/setApiStatus`,
  (resolve) =>
    <T extends keyof AuthApiRequests>(payload: AuthStatusUpdates<T>) =>
      resolve(payload)
);
