import { all } from 'redux-saga/effects';
import * as ducks from '../ducks';

const { auth, userProfile, userTracing, billing } = ducks;

export default function* saga() {
  yield all([
    auth.saga(),
    userProfile.saga(),
    userTracing.saga(),
    billing.saga(),
  ]);
}
