import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { getCurrentUser } from '../../../firebaseHelpers/firebaseHelpers';
import Routes from '../../../routes';

export interface NewUserRouteProps
  extends Exclude<RouteProps, 'render' | 'component'> {
  component: React.ComponentType;
}

// really a route for onboarding a new company should not be accessible for all users
export default function NewUserRoute({
  component: Component,
  ...rest
}: NewUserRouteProps) {
  const currentUser = getCurrentUser();
  // const userProfile = useSelector(ducks.userProfile.selectors.getUserProfile);

  return (
    <Route
      {...rest}
      render={(props) =>
        currentUser && currentUser.emailVerified ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: Routes.LogIn,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
