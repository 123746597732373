import { combineReducers } from 'redux';
import produce from 'immer';
import { initialized } from './shared.actions';
import { createReducer } from 'deox';

const appInitialized = createReducer(false, (handleAction) => [
  handleAction(
    initialized,
    produce((_, __) => true)
  ),
]);

export default combineReducers({
  appInitialized,
});
