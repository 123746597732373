import React from 'react';
import Box from '@material-ui/core/Box';
import Button, { ButtonProps } from '@material-ui/core/Button';
import * as S from './ProgressButton.styled';

interface ProgressButtonProps {
  className?: string;
  showProgress?: boolean;
  buttonProps?: Partial<Omit<ButtonProps, 'className'>>;
}

export function ProgressButton({
  showProgress = false,
  className = '',
  buttonProps = {},
}: ProgressButtonProps) {
  return (
    <Box position="relative">
      <Button {...buttonProps} className={className} />

      {showProgress && <S.ButtonCircularProgress size={24} />}
    </Box>
  );
}

export default ProgressButton;
