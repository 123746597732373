import { ApolloClient, InMemoryCache, from, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { RetryLink } from '@apollo/client/link/retry';
import { getIdToken, FirebaseAuth } from '../firebaseHelpers/firebaseHelpers';

const possibleTypes = {
  AdResources: [
    'CarouselResources',
    'GifResources',
    'PictureResources',
    'PollResources',
  ],
  MutationResponse: [
    'AddCardMutationResponse',
    'ConvertAdMutationResponse',
    'CreateAdMutationResponse',
    'CreateBillingAccountMutationResponse',
    'CreateCatalogMutationResponse',
    'CreateUserProfileWithCompanyMutationResponse',
    'CreateUserProfileWithTeamMutationResponse',
    'CreateUserTraceResponse',
    'DeleteDraftAdMutationResponse',
    'EditCardMutationResponse',
    'RemoveCardMutationResponse',
    'RemoveCatalogMutationResponse',
    'ReplaceCardMutationResponse',
    'SetPrimaryCardIdMutationResponse',
    'StopAdMutationResponse',
    'UpdateAdMutationResponse',
    'UpdateBusinessInfoMutationResponse',
    'UpdateBusinessVerificationStatusMutationResponse',
    'UpdateHttpInfoResponse',
    'UpdatePageStatisticsResponse',
    'UpdateProfileImageResponse',
    'UpdateUserOnboardedMutationResponse',
  ],
  UserProfileResult: ['UserProfile', 'UserProfileNotFound'],
  BillingAccountResult: ['BillingAccount', 'BillingAccountNotFound'],
  UserTraceResult: ['UserTrace', 'UserTraceNotFound'],
};

export const cache = new InMemoryCache({
  possibleTypes,
});

const consoleLink = new ApolloLink((operation, forward) => {
  console.log(`starting request for ${operation.operationName}`);
  return forward(operation).map((data) => {
    console.log(`ending request for ${operation.operationName}`);
    return data;
  });
});

let idToken: string | null;
const withFirebaseIdToken = setContext(async (_, { headers }) => {
  console.log('withFirebaseIdToken link');
  if (!idToken) {
    console.log('id token will be set now');
    idToken = await getIdToken();
  }
  return {
    headers: { 'x-identity-token': idToken, ...headers },
  };
});

FirebaseAuth.attachAuthStateChangeListener((it) => {
  if (!it) {
    idToken = null;
  }
});

const resetFirebaseIdToken = onError(
  ({ graphQLErrors, networkError, response }) => {
    console.log('resetFirebaseIdToken link');
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, extensions }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Extension Code: ${extensions.code}`
        );
        if (extensions.code === 'UNAUTHENTICATED') {
          console.log('resetting token due to AuthenticationError');
          idToken = null;
        }
      });

    if (networkError) console.log(`[Network error]: ${networkError}`);

    if (networkError && networkError.name === 'ServerError') {
      // remove cached token on 401 from the server
      console.log('resetting token due to networkError');
      idToken = null;
    }
  }
);

const authRetryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error, _operation) => !!error,
  },
});

const batchHttpLink = new BatchHttpLink({
  uri: process.env.REACT_APP_GATEWAY_URL,
  batchMax: 5, // No more than 5 operations per batch
  batchInterval: 20, // Wait no more than 20ms after first batched operation
});

const apolloLinks = [
  process.env.NODE_ENV === 'production' ? null : consoleLink,
  authRetryLink,
  resetFirebaseIdToken,
  withFirebaseIdToken,
  batchHttpLink,
].filter((x): x is ApolloLink => !!x);

const client = new ApolloClient({
  link: from(apolloLinks),
  cache,
});

export default client;
