import React from 'react';
import { useLocation } from 'react-router-dom';

import CreditCard from '@material-ui/icons/CreditCard';
import Group from '@material-ui/icons/Group';
import SupervisedUserCircleRounded from '@material-ui/icons/SupervisedUserCircleRounded';
import VisibilityRounded from '@material-ui/icons/VisibilityRounded';
// import Settings from '@material-ui/icons/Settings';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction';

import * as S from './SwayMeeAppSideMenu.styled';

import { useRouting, useString } from '../../hooks';
import Routes from '../../routes';

interface AppSideMenuListItem {
  route: Routes;
  icon: React.ReactNode;
  disabled?: boolean;
  secondaryIcon?: React.ReactNode;
}

type MenuItemRecords = Record<string, AppSideMenuListItem>[];

export interface AppSideMenuListProps {
  onClose?: () => void;
}

const AppListContent: MenuItemRecords = [
  {
    'Ad Campaign': { route: Routes.AdCampaign, icon: <VisibilityRounded /> },
    'Ad Insights': {
      route: Routes.Analytics,
      icon: <VisibilityRounded />,
    },
    'My Business': {
      route: Routes.MyBusiness,
      icon: <SupervisedUserCircleRounded />,
    },
    Billing: { route: Routes.Billing, icon: <CreditCard /> },
    Team: { route: Routes.Team, icon: <Group /> },
  },
  // {
  //   Settings: { route: Routes.Settings, icon: <Settings /> },
  // },
];

export default function SwayMeeAppSideMenuList({
  onClose,
}: AppSideMenuListProps) {
  const [goTo] = useRouting();
  const location = useLocation();
  const path = location.pathname;
  const [currentPath] = useString(path);

  React.useEffect(() => {
    currentPath.setValue(path);
  }, [path, currentPath]);

  // handle item click thunk
  const handleListItemClick =
    (route: Routes) =>
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onClose && onClose();
      goTo(route);
    };

  return (
    <div>
      <S.List>
        {AppListContent.map((section, idx) => {
          return (
            <React.Fragment key={`SideMenuSection${idx}`}>
              {Object.entries(section).map(([desc, routes], _) => {
                const route = routes.route;
                return (
                  <S.ListItem
                    button={!routes.disabled}
                    key={`MenuListItem${desc}`}
                    selected={currentPath.value.startsWith(route)}
                    onClick={handleListItemClick(route)}
                    style={{
                      opacity: !!routes.disabled ? 0.6 : 1,
                      pointerEvents: routes.disabled ? 'none' : 'all',
                      userSelect: 'none',
                    }}
                  >
                    <ListItemIcon>{routes.icon}</ListItemIcon>
                    <ListItemText primary={desc} />
                    {!!routes.secondaryIcon && (
                      <ListItemSecondaryAction style={{ display: 'flex' }}>
                        {routes.secondaryIcon}
                      </ListItemSecondaryAction>
                    )}
                  </S.ListItem>
                );
              })}
              {idx + 1 < AppListContent.length && (
                <Divider orientation="horizontal" variant="fullWidth" />
              )}
            </React.Fragment>
          );
        })}
      </S.List>
    </div>
  );
}
