import { gql } from '@apollo/client';
import client from './common.api';
import moment from 'moment';

export enum AdResourceTypes {
  Picture = 'Picture',
  Carousel = 'Carousel',
  Gif = 'Gif',
  Poll = 'Poll',
}

export const AD_TYPES_CATALOG_ID_TO_AD_TYPES: {
  [key: string]: AdResourceTypes;
} = {
  '79nEIpBI5q62a7HN0pFj': AdResourceTypes.Carousel,
  T5RlTNO1WXigUglyjafY: AdResourceTypes.Picture,
  l45ysi09IC6JuYSZ8IQn: AdResourceTypes.Poll,
  pfaFfC3O0axG8YRvxFre: AdResourceTypes.Gif,
} as const;

export const AD_TYPES_TO_AD_TYPES_CATALOG_ID: Record<
  keyof typeof AdResourceTypes,
  string
> = {
  [AdResourceTypes.Carousel]: '79nEIpBI5q62a7HN0pFj',
  [AdResourceTypes.Picture]: 'T5RlTNO1WXigUglyjafY',
  [AdResourceTypes.Poll]: 'l45ysi09IC6JuYSZ8IQn',
  [AdResourceTypes.Gif]: 'pfaFfC3O0axG8YRvxFre',
} as const;

export type AdTypesCatalogEntry = {
  adTypesCatalogId: string;
  displayName: string;
  iconImageUrl: string;
  amount: number;
  currency: string;
};

export const ALL_AD_TYPES_CATALOG_ENTRY_FIELDS = `
adTypesCatalogId
displayName
iconImageUrl
amount
currency
`;

export async function getAdTypesCatalog(): Promise<
  AdTypesCatalogEntry[] | null
> {
  type AdTypesCatalogQuery = {
    adTypesCatalog: AdTypesCatalogEntry[];
  };
  const query = gql`
    {
      adTypesCatalog {
        ${ALL_AD_TYPES_CATALOG_ENTRY_FIELDS}
      }
    }
  `;
  const result = await client.query<AdTypesCatalogQuery>({
    query,
  });
  return result.data?.adTypesCatalog ?? null;
}

export async function getAdTypesCatalogEntrySnapshotPricing(
  adTypesCatalogId: string,
  time: moment.Moment
): Promise<number | null> {
  type Snapshot = {
    pricingSnapshot: number;
  };
  type GetAdTypesCatalogEntrySnapshotPricingQuery = {
    adTypesCatalogEntrySnapshot: Snapshot;
  };
  const query = gql`
    {
      adTypesCatalogEntrySnapshot(adTypesCatalogId: "${adTypesCatalogId}", snapshotISO8601:"${time.toISOString()}") {
        pricingSnapshot
      }
    }
  `;
  const result = await client.query<GetAdTypesCatalogEntrySnapshotPricingQuery>(
    {
      query,
    }
  );
  return result.data?.adTypesCatalogEntrySnapshot.pricingSnapshot ?? null;
}
