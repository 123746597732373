import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Catalog } from '../../types';

const REMOVE_CATALOG_MUTATION = gql`
  mutation RemoveCatalog($catalogId: String!, $companyID: String) {
    removeCatalog(catalogId: $catalogId, companyID: $companyID) {
      catalog {
        catalogId
        displayName
      }
    }
  }
`;

type RemoveCatalogResponse = {
  removeCatalog: { catalog: Catalog };
};

type RemoveCatalogOperationVariables = {
  catalogId: string;
  companyID: string;
};

export function useRemoveCatalog() {
  const [execute, result] = useMutation<
    RemoveCatalogResponse,
    RemoveCatalogOperationVariables
  >(REMOVE_CATALOG_MUTATION);

  const createVariables = useCallback<
    (catalogId: string, companyID: string) => any
  >(
    (catalogId: string, companyID: string) => ({
      variables: { catalogId, companyID },
    }),
    []
  );

  return {
    execute,
    createVariables,
    ...result,
  };
}
