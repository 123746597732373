import styled from 'styled-components';
import MuiLink from '@material-ui/core/Link';
import MuiIconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MuiToolbar from '@material-ui/core/Toolbar';
import MuiAppBar from '@material-ui/core/AppBar';
import MuiButton from '@material-ui/core/Button';
import { mediaQuery } from '../../mixins';
import { ReactComponent as SwayMeeLogoSymbol } from '../../assets/images/logo/swaymee/svgs/sym-green.svg';

const TOOL_BAR_VERT_SPACING = 5;

/**
 * Styled Components for AppBar
 */
export const IconButton = styled(MuiIconButton)`
  margin-right: ${(props) => props.theme.spacing(0)}px;
  ${(props) => mediaQuery(props.theme.breakpoints.up('sm'))`
      display: none;
    `}
`;

export const Logo = styled(SwayMeeLogoSymbol)`
  display: flex;
  margin-right: ${(props) => props.theme.spacing(1)}px;
  width: auto;
  max-height: 64px;
`;

export const Title = styled(Typography)`
  flex-grow: 1;
  padding-right: ${(props) => props.theme.spacing(1)}px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Link = styled(MuiLink)`
  color: ${(props) => props.theme.palette.background.paper};
`;

type ButtonProps = {
  buttonColor: string;
};

export const Button = styled(MuiButton).withConfig({
  shouldForwardProp: (prop) => !['buttonColor'].includes(prop),
})<ButtonProps>`
  color: ${(props) => props.buttonColor};
`;

export const Toolbar = styled(MuiToolbar)`
  min-height: ${(props) => props.theme.spacing(TOOL_BAR_VERT_SPACING)}px;
  height: ${(props) => props.theme.spacing(TOOL_BAR_VERT_SPACING)}px;
`;

export const AppBar = styled(MuiAppBar)`
  position: fixed;
  padding: ${({ theme }) => theme.spacing(0, 2, 0, 2)};
  z-index: ${(props) => props.theme.zIndex.modal + 1};
`;
