import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { ALL_USER_PROFILE_MINUS_TEAM_MEMBERS } from '../../constants';
import { UserProfile } from '../../types';

const CREATE_USER_PROFILE_WITH_TEAM_MUTATION = gql`
  mutation CreateUserProfileWithTeam($input: CreateUserProfileWithTeamInput!) {
    createUserProfileWithTeam(input: $input) {
      success
      code
      message
      userProfile {
        ${ALL_USER_PROFILE_MINUS_TEAM_MEMBERS}
      }
    }
  }
`;

export type CreateUserProfileWithTeamResponse = {
  createUserProfileWithTeam: {
    success: boolean;
    userProfile?: UserProfile;
  };
};

type CreateUserProfileWithTeamOperationVariables = {
  input: {
    email: string;
    accessCode: string;
  };
};

export function useCreateUserProfileWithTeam() {
  const [execute, result] = useMutation<
    CreateUserProfileWithTeamResponse,
    CreateUserProfileWithTeamOperationVariables
  >(CREATE_USER_PROFILE_WITH_TEAM_MUTATION);

  const createVariables = useCallback<
    (a: CreateUserProfileWithTeamOperationVariables['input']) => any
  >(
    ({ accessCode, email }) => ({
      variables: { input: { email, accessCode } },
    }),
    []
  );

  return {
    execute,
    createVariables,
    ...result,
  };
}

export default useCreateUserProfileWithTeam;
