import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import * as S from './SwayMeeAppSideMenu.styled';
import { Toolbar as SwayMeeAppToolbar } from './SwayMeeAppBar.styled';
import SwayMeeAppSideMenuList, {
  AppSideMenuListProps,
} from './SwayMeeAppSideMenuList';
import LegalFooter from '../Legal/LegalFooter';

export interface SwayMeeAppSideMenuProps extends AppSideMenuListProps {
  isOpen: Boolean;
}

export default ({ isOpen, onClose }: SwayMeeAppSideMenuProps) => {
  const [mobileOpen, setMobileOpen] = React.useState(isOpen);

  React.useEffect(() => {
    setMobileOpen(isOpen);
  }, [isOpen, setMobileOpen]);

  const closeMenu = () => {
    onClose && onClose();
    setMobileOpen(false);
  };

  return (
    <S.Nav aria-label="business user menu">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp>
        <S.Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={closeMenu}
          anchor="left"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          PaperProps={{
            elevation: 0,
          }}
        >
          <SwayMeeAppToolbar />
          <S.DrawerContents>
            <SwayMeeAppSideMenuList onClose={onClose} />
            <LegalFooter captions={['privacy', 'terms']} />
          </S.DrawerContents>
        </S.Drawer>
      </Hidden>
      <Hidden xsDown>
        <S.Drawer variant="permanent" open>
          <SwayMeeAppToolbar />
          <S.DrawerContents>
            <SwayMeeAppSideMenuList onClose={onClose} />
            <LegalFooter captions={['privacy', 'terms']} />
          </S.DrawerContents>
        </S.Drawer>
      </Hidden>
    </S.Nav>
  );
};
