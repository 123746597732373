import React from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Visibility from '@material-ui/icons/VisibilityRounded';
import VisibilityOff from '@material-ui/icons/VisibilityOffRounded';
import { useObject, useAsyncFunc } from '../../../hooks';
// import Routes from '../../../routes';

import * as S from './ConfirmationByReauthentication.styled';
import TextField from '@material-ui/core/TextField/TextField';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import IconButton from '@material-ui/core/IconButton/IconButton';
import {
  FirebaseAuth,
  FirebaseAuthVoidResponse,
  getCurrentUser,
} from '../../../firebaseHelpers/firebaseHelpers';
import { ApiCallStatus } from '../../../ducks/shared/shared.constants';
import { DialogProps, Typography } from '@material-ui/core';

interface ReauthenticationState {
  password: string;
  passwordError: string;
  logInError: string;
}

const DEFAULT_STATE: ReauthenticationState = {
  password: '',
  passwordError: '',
  logInError: '',
};

export interface ConfirmationByReauthenticationProps {
  className?: string;
  open: boolean;
  title?: React.ReactNode;
  content: React.ReactNode;
  onClose: () => void;
  onConfirmationComplete: () => void;
}

export default function ConfirmationByReauthentication({
  className,
  open,
  title,
  content,
  onConfirmationComplete,
  onClose,
}: ConfirmationByReauthenticationProps) {
  const [
    {
      value: state,
      setValue: setState,
      updateProp: updateStateProp,
      updateMultiple: updateStateMultiple,
    },
  ] = useObject<ReauthenticationState>(DEFAULT_STATE);
  const [showPassword, setShowPassword] = React.useState(false);
  const [reauthWithPasswordAsync] = useAsyncFunc<FirebaseAuthVoidResponse>();
  const onConfirmationCompleteRef = React.useRef(onConfirmationComplete);

  React.useEffect(() => {
    onConfirmationCompleteRef.current = onConfirmationComplete;
  });

  const resetState = React.useCallback(() => {
    setState(DEFAULT_STATE);
  }, [setState]);

  const handleClickShowPassword = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleConfirm = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    reauthWithPasswordAsync.execute(
      FirebaseAuth.reauthenticateCurrentUserWithPassword(state.password)
    );
  };

  const handleConfirmationComplete = React.useCallback(
    (response: FirebaseAuthVoidResponse) => {
      if (response.error) {
        updateStateProp('logInError', response.error.message);
      } else {
        resetState();
        if (onConfirmationCompleteRef.current) {
          onConfirmationCompleteRef.current();
        }
      }
    },
    [updateStateProp, resetState]
  );

  React.useEffect(() => {
    if (reauthWithPasswordAsync.status === ApiCallStatus.Success) {
      handleConfirmationComplete(reauthWithPasswordAsync.value!);
    }
  }, [
    reauthWithPasswordAsync.status,
    reauthWithPasswordAsync.value,
    handleConfirmationComplete,
  ]);

  const handleDialogOnClose: DialogProps['onClose'] = (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    resetState();
    onClose();
  };
  const handleClose = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    resetState();
    onClose();
  };

  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value.trim();
    updateStateMultiple({
      password: value,
      passwordError: value === '' ? 'password required' : '',
    });
  };

  const canSubmitForm = (): Boolean => {
    const isFormComplete = state.password !== '';
    const formHasErrors = state.passwordError !== '';

    return isFormComplete && !formHasErrors;
  };

  return (
    <div className={className}>
      <S.Dialog
        onClose={handleDialogOnClose}
        aria-labelledby="confirmation-modal"
        open={open}
      >
        <S.DialogTitle id="confirm-dialog" disableTypography>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <S.IconButton
              aria-label="settings"
              size="small"
              onClick={handleClose}
            >
              <CloseRoundedIcon />
            </S.IconButton>
          </Box>
        </S.DialogTitle>
        <S.Divider />
        <S.DialogContent>
          <S.AuthPaper>
            <S.AuthAvatar>
              <LockOutlinedIcon />
            </S.AuthAvatar>

            {title && (
              <Typography variant="h6" align="center">
                {title}
              </Typography>
            )}

            <S.FormMessageContent>{content}</S.FormMessageContent>

            <S.AuthForm noValidate>
              <S.PrefilledTextField
                variant="outlined"
                margin="dense"
                fullWidth
                id="email"
                label="Account Managed By"
                name="email"
                value={open && getCurrentUser()!!.email}
                disabled={true}
              />

              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                value={state.password}
                error={state.passwordError !== ''}
                helperText={state.passwordError}
                onChange={handlePasswordChange}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                autoFocus
              />

              <S.ErrorMessage variant="caption" color="error">
                {state.logInError}
              </S.ErrorMessage>

              {/* TODO: Handle forgot password case when reauthenticating */}
              {/* <RouterLink to={Routes.PasswordReset}>
                <S.FooterLink color="primary" variant="body2">
                  Forgot password?
                </S.FooterLink>
              </RouterLink> */}
            </S.AuthForm>
          </S.AuthPaper>
        </S.DialogContent>
        <S.Divider />
        <S.DialogActions>
          <S.Button
            variant="outlined"
            color="primary"
            size="medium"
            onClick={handleClose}
          >
            {'Cancel'}
          </S.Button>
          <S.ProgressButton
            showProgress={
              reauthWithPasswordAsync.status === ApiCallStatus.Pending
            }
            buttonProps={{
              variant: 'contained',
              color: 'primary',
              size: 'medium',
              onClick: handleConfirm,
              disabled: !canSubmitForm(),
              children: 'Submit',
            }}
          />
        </S.DialogActions>
      </S.Dialog>
    </div>
  );
}
