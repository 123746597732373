import {
  NAME,
  BillingStatusUpdates,
  BillingApiRequests,
} from './billing.constants';
import { ActionType, createAction } from 'deox';
import { Types } from '../../api';

export const fetchBilling = createAction(`${NAME}/fetchBilling`);
export const fetchBillingSuccess = createAction(
  `${NAME}/fetchBillingSuccess`,
  (resolve) => (payload: Types.BillingAccountResult) => resolve(payload)
);
export const fetchBillingFailure = createAction(
  `${NAME}/fetchBillingFailure`,
  (resolve) => (payload: any) => resolve(payload)
);
export function isFetchBillingSuccess(
  arg: ActionType<typeof fetchBillingFailure | typeof fetchBillingSuccess>
): arg is ActionType<typeof fetchBillingSuccess> {
  return arg.type === fetchBillingSuccess.type;
}

export const createBilling = createAction(
  `${NAME}/createBilling`,
  (resolve) => (getStartingCardId: null | (() => Promise<string | null>)) =>
    resolve(getStartingCardId)
);
export const createBillingSuccess = createAction(
  `${NAME}/createBillingSuccess`,
  (resolve) => (payload: Types.BillingAccount) => resolve(payload)
);
export const createBillingFailure = createAction(
  `${NAME}/createBillingFailure`,
  (resolve) => (payload: any) => resolve(payload)
);

export const updateBilling = createAction(
  `${NAME}/updateBilling`,
  (resolve) =>
    (
      payload: Partial<
        Pick<Types.BillingAccount, 'cards' | 'primaryCardId' | 'accountStatus'>
      >
    ) =>
      resolve(payload)
);

type AddCardRequest = {
  cardId: string;
};
export const addCard = createAction(
  `${NAME}/addCard`,
  (resolve) => (payload: AddCardRequest) => resolve(payload)
);

type RemoveCardRequest = {
  cardId: string;
};
export const removeCard = createAction(
  `${NAME}/removeCard`,
  (resolve) => (payload: RemoveCardRequest) => resolve(payload)
);

type EditCardRequest = Omit<Types.EditCardMutationInput, 'companyID'>;
export const editCard = createAction(
  `${NAME}/editCard`,
  (resolve) => (payload: EditCardRequest) => resolve(payload)
);

type ReplaceCardRequest = Types.ReplaceCardMutationInput;
export const replaceCard = createAction(
  `${NAME}/replaceCard`,
  (resolve) => (payload: ReplaceCardRequest) => resolve(payload)
);

type SetPrimaryCardIdRequest = {
  cardId: string;
};
export const setPrimaryCardId = createAction(
  `${NAME}/setPrimaryCardId`,
  (resolve) => (payload: SetPrimaryCardIdRequest) => resolve(payload)
);

export const setApiStatus = createAction(
  `${NAME}/setApiStatus`,
  (resolve) =>
    <T extends keyof BillingApiRequests>(payload: BillingStatusUpdates<T>) =>
      resolve(payload)
);
