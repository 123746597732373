import React from 'react';
import Snackbar, {
  SnackbarProps,
  SnackbarCloseReason,
} from '@material-ui/core/Snackbar';
import Alert, { AlertProps } from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { noop } from '../utils';

export interface SnackBarAlertProps
  extends Pick<AlertProps, 'severity' | 'variant'>,
    Omit<SnackbarProps, 'onClose'> {
  title?: string;
  message: React.ReactNode;
  onClose?: (
    event: React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => any;
}

export default function SnackBarAlert({
  title,
  message,
  severity,
  variant,
  open,
  onClose = noop,
  ...otherProps
}: SnackBarAlertProps) {
  const [alertOpen, setAlertOpen] = React.useState(open);

  React.useEffect(() => {
    setAlertOpen(open);
  }, [open, setAlertOpen]);

  const handleClose = (
    event: React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose(event, reason);
    setAlertOpen(false);
  };

  return (
    <Snackbar open={alertOpen} onClose={handleClose} {...otherProps}>
      <Alert
        elevation={6}
        variant={variant}
        onClose={handleClose}
        severity={severity}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
      </Alert>
    </Snackbar>
  );
}
