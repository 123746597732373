import { useCallback } from 'react';
import { gql, useQuery, useLazyQuery, QueryLazyOptions } from '@apollo/client';
import { CompanyAdMetrics } from '../../types';

const GET_COMPANY_AD_METRICS = gql`
  query GetCompanyAdMetrics($companyId: String!, $adId: String!) {
    companyAdMetrics(companyId: $companyId, adId: $adId) {
      reminderStat {
        count {
          monthly
          weekly
          daily
        }
        uniqueCount {
          monthly
          weekly
          daily
        }
        rateOfChange {
          monthly
          weekly
          daily
        }
      }
      adClickStat {
        count {
          monthly
          weekly
          daily
        }
        uniqueCount {
          monthly
          weekly
          daily
        }
        rateOfChange {
          monthly
          weekly
          daily
        }
      }
      adLikeStat {
        count {
          monthly
          weekly
          daily
        }
        rateOfChange {
          monthly
          weekly
          daily
        }
      }
      generationDateEpoch
    }
  }
`;

type GetCompanyAdMetricsResponse = {
  companyAdMetrics: CompanyAdMetrics;
};
type GetCompanyAdMetricsOperationVariables = {
  companyId: string;
  adId: string;
};
type GetCompanyAdMetricRequest = GetCompanyAdMetricsOperationVariables;

export function useGetCompanyAdMetrics({
  companyId,
  adId,
}: GetCompanyAdMetricRequest) {
  return useQuery<
    GetCompanyAdMetricsResponse,
    GetCompanyAdMetricsOperationVariables
  >(GET_COMPANY_AD_METRICS, {
    variables: { companyId, adId },
  });
}

export function useLazyGetCompanyAdMetrics() {
  const [execute, result] = useLazyQuery<
    GetCompanyAdMetricsResponse,
    GetCompanyAdMetricsOperationVariables
  >(GET_COMPANY_AD_METRICS);

  const createVariables = useCallback<
    (
      a: GetCompanyAdMetricsOperationVariables
    ) => QueryLazyOptions<GetCompanyAdMetricsOperationVariables>
  >(
    ({ companyId, adId }) => ({
      variables: { companyId, adId },
    }),
    []
  );

  return {
    execute,
    createVariables,
    ...result,
  };
}
