import { gql } from '@apollo/client';
import { UserAgentInformation } from '../utils/UserInfo';
import client from './common.api';
//import axios, { AxiosRequestConfig } from 'axios';
import { isObjectEmpty } from '../utils/ObjectHelpers';
import { UserTraceResult } from './types';

export type PageStatistics = {
  lastVisited: string;
  visitCount: number;
};

export type PagesVisitedStatistics = {
  [pageKey: string]: PageStatistics;
};

type UserAgent = UserAgentInformation;

export type HTTPInfo = {
  userAgents: UserAgent[];
};

export type UserTrace = {
  userId: string;
  pagesVisitedStatistics?: PagesVisitedStatistics;
};

export type PageVisited = {
  pageKey: string;
  visitedAt: moment.Moment;
};

type MutationResponse = {
  code: number;
  success: boolean;
  message: string;
};

type UserTraceMutationResponse = MutationResponse & {
  userTrace?: UserTrace;
};

// GQL query strings
const USER_TRACE_ALL_FIELDS = 'userId,pageStatistics';

export async function createUserTrace(): Promise<UserTrace | null> {
  type CreateUserTraceMutation = {
    createUserTrace: UserTraceMutationResponse;
  };
  const mutation = gql`
		mutation {
			createUserTrace {
				userTrace {
					${USER_TRACE_ALL_FIELDS}
				}
			}
		}
	`;
  const result = await client.mutate<CreateUserTraceMutation>({
    mutation,
  });
  return result.data!.createUserTrace.userTrace ?? null;
}

export async function getUserTrace(): Promise<UserTraceResult> {
  type UserTraceQuery = {
    userTrace: UserTraceResult;
  };
  const query = gql`
		query GetUserTrace {
			userTrace {
        __typename
        ... on UserTrace {
          ${USER_TRACE_ALL_FIELDS}
        }
        ... on UserTraceNotFound {
          reason
        }
      }
		}
	`;

  const result = await client.query<UserTraceQuery>({
    query,
    // TODO: once we pul fetching of this data in react should not need this line
    fetchPolicy: 'no-cache',
  });
  return result.data?.userTrace ?? null;
}

export async function updatePageStatistics(
  page: PageVisited
): Promise<UserTrace | null> {
  type UpdatePageStatsMutation = {
    updatePageStatistics: UserTraceMutationResponse;
  };
  const mutation = gql`
		mutation UpdatePageStatistics($updatePageStatisticsInput: UpdatePageStatisticsInput!){
			updatePageStatistics(updatePageStatisticsInput: $updatePageStatisticsInput) {
				userTrace {${USER_TRACE_ALL_FIELDS}}
			}
		}
	`;
  const result = await client.mutate<UpdatePageStatsMutation>({
    mutation,
    variables: {
      updatePageStatisticsInput: {
        pageKey: page.pageKey,
        visitedAt: page.visitedAt.toISOString(),
      },
    },
  });
  return result.data?.updatePageStatistics.userTrace ?? null;
}

export async function updateUserHTTPInformation(
  data: UserAgentInformation
): Promise<UserTrace | null> {
  type UpdateHTTPInfoMutation = {
    updateHTTPInfo: UserTraceMutationResponse;
  };
  const mutation = gql`
		mutation UpdateHTTPInfo($updateHTTPInfoInput: UpdateHttpInfoInput!){
			updateHTTPInfo(updateHTTPInfoInput: $updateHTTPInfoInput) {
				userTrace {${USER_TRACE_ALL_FIELDS}}
			}
		}
	`;
  const result = await client.mutate<UpdateHTTPInfoMutation>({
    mutation,
    variables: {
      updateHTTPInfoInput: {
        userAgents: [data],
      },
    },
  });
  return result.data?.updateHTTPInfo.userTrace ?? null;
}

const constructUserAgentInput = (data: UserAgentInformation): string => {
  const putIfDefined = (obj: any, keys: string[]) => {
    return `${keys
      .filter((key) => !!obj[key])
      .map((key) => `${key}: "${obj[key]}"`)
      .join(', ')}`;
  };
  return `
		{
			${putIfDefined(data, ['ua', 'browser', 'device', 'operatingSystem', 'ip'])}
			${
        !isObjectEmpty(data.geolocation)
          ? `, geolocation: {${putIfDefined(
              data.geolocation,
              Object.keys(data.geolocation!)
            )}}`
          : ''
      }
		}
	`;
};
