import React from 'react';
import { UseObjectResult } from '../../../hooks';
import { SnackBarAlertProps } from './SnackBarAlert';

type AppAlertState = SnackBarAlertProps;
export const AppAlertContext = React.createContext<
  UseObjectResult<AppAlertState>
>({} as any);

export const DEFAULT_APP_ALERT_STATE: AppAlertState = {
  message: '',
  variant: 'filled',
  anchorOrigin: { vertical: 'top', horizontal: 'center' },
};

export default function useAppAlertContext() {
  const appAlertContext = React.useContext(AppAlertContext);
  if (process.env.NODE_ENV !== 'production' && !appAlertContext) {
    console.warn(
      'AppAlertContext cannot be undefined in useAppAlertContext hook'
    );
  }
  const { updateMultiple: updateMultipleAppAlertState } = appAlertContext || {};

  /**
   * alert function is meant to display simple textual alert to user for given severity. More complex or customized alerts can be created
   * via mutation of the appAlertContext.
   * @param message message to display to user in alert
   * @param severity severity of alert (default: info)
   */
  const alert = React.useCallback(
    ({
      message,
      severity = 'info',
    }: Pick<AppAlertState, 'message' | 'severity'>) => {
      updateMultipleAppAlertState({
        open: true,
        message,
        severity,
        autoHideDuration: severity === 'success' ? 3000 : null,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return [{ appAlertContext, alert }];
}
