import { ApiCallStatus } from '../shared/shared.constants';

export const NAME = 'auth';

export enum AuthApiRequestTypes {
  CreateAccount = 'CreateAccount',
  LogIn = 'LogIn',
  LogOut = 'LogOut',
  DeleteAccount = 'DeleteAccount',
  PasswordReset = 'PasswordReset',
  EmailVerification = 'EmailVerification',
}

export type AuthApiRequests = {
  [AuthApiRequestTypes.CreateAccount]: {
    status: ApiCallStatus;
    error?: firebase.FirebaseError;
  };
  [AuthApiRequestTypes.LogIn]: {
    status: ApiCallStatus;
    error?: firebase.FirebaseError;
  };
  [AuthApiRequestTypes.LogOut]: {
    status: ApiCallStatus;
    error?: firebase.FirebaseError;
  };
  [AuthApiRequestTypes.DeleteAccount]: {
    status: ApiCallStatus;
    error?: firebase.FirebaseError;
  };
  [AuthApiRequestTypes.PasswordReset]: {
    status: ApiCallStatus;
    error?: firebase.FirebaseError;
  };
  [AuthApiRequestTypes.EmailVerification]: {
    status: ApiCallStatus;
    error?: firebase.FirebaseError;
  };
};

export type AuthStatusUpdates<P extends keyof AuthApiRequests> = {
  requestType: P;
  update: AuthApiRequests[P];
};

export function createUpdate<P extends keyof AuthApiRequests>(
  requestType: P,
  update: AuthApiRequests[P]
): AuthStatusUpdates<P> {
  return {
    requestType,
    update,
  };
}
