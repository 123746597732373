import React, { useEffect } from 'react';
import firebase from 'firebase/app';
import { useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Typography from '@material-ui/core/Typography/Typography';
import { Box, Collapse } from '@material-ui/core';
import AccountActionIcon from '@material-ui/icons/AccountCircleSharp';
import LogoutIcon from '@material-ui/icons/ExitToAppRounded';
// import LockIcon from '@material-ui/icons/LockOpenRounded';
import DeleteIcon from '@material-ui/icons/DeleteOutlineRounded';
import OpenInNewIcon from '@material-ui/icons/OpenInNewRounded';
import ReauthenticationConfirmation from '../../components/auth/AuthCommon';

import * as S from './AccountActionsMenu.styled';
import Routes from '../../routes';
import useAppAlertContext from '../common/SnackBarAlert/SnackBarAlert.hooks';
import { useDispatch, useSelector } from 'react-redux';
import * as auth from '../../ducks/auth';
import { ApiCallStatus } from '../../ducks/shared/shared.constants';

export interface AccountActionsMenuProps {
  color?: string;
  className?: string;
}

function AccountActionsMenu({ color, className }: AccountActionsMenuProps) {
  const TOOLTIP_TEXT = `Account Actions for ${
    firebase.auth().currentUser?.email
  }`;
  const theme = useTheme();
  const dispatch = useDispatch();
  const authApiRequests = useSelector(auth.selectors.getApiRequests);
  const [{ alert }] = useAppAlertContext();
  const [popoverAnchorEl, setPopoverAnchorEl] =
    React.useState<HTMLDivElement | null>(null);
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const popoverAnchorRef = React.useRef<HTMLDivElement | null>(null);

  const [confirmDeleteAccountModalOpen, setConfirmDeleteAccountModalOpen] =
    React.useState(false);

  const handlePopoverClose = React.useCallback(() => {
    setPopoverOpen(false);
    setPopoverAnchorEl(null);
  }, [setPopoverOpen, setPopoverAnchorEl]);

  const togglePopoverOpen = () => {
    const previouslyOpen = popoverOpen;
    setPopoverOpen((previouslyOpen) => !previouslyOpen);
    if (previouslyOpen) {
      setPopoverAnchorEl(null);
    }
  };

  React.useEffect(() => {
    if (popoverAnchorRef && popoverAnchorRef.current && !popoverAnchorEl) {
      setPopoverAnchorEl(popoverAnchorRef.current);
    }
  }, [setPopoverAnchorEl, popoverAnchorEl]);

  // const onChangePasswordClick = (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   event.preventDefault();
  //   alert({message: 'changing password ...'});
  // };

  const onDeleteAccountClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setConfirmDeleteAccountModalOpen(true);
  };

  const handleDeleteAccountConfirmationApprove = React.useCallback(
    async () => {
      setConfirmDeleteAccountModalOpen(false);
      dispatch(auth.actions.deleteAccount());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleDeleteAccountConfirmationDeny = () => {
    setConfirmDeleteAccountModalOpen(false);
    handlePopoverClose();
  };

  const onLogoutClick = React.useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setConfirmDeleteAccountModalOpen(false);
      dispatch(auth.actions.logout());
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      authApiRequests.LogOut.status === ApiCallStatus.Error ||
      authApiRequests.DeleteAccount.status === ApiCallStatus.Error
    ) {
      alert({
        message:
          'We experienced an issue trying to log out of your account. Please try again later.',
        severity: 'error',
      });
      handlePopoverClose();
    }
  }, [
    alert,
    authApiRequests.LogOut.status,
    authApiRequests.DeleteAccount.status,
    handlePopoverClose,
  ]);

  const goToTerms = () => {
    const win = window.open(Routes.Terms, '_blank');
    if (win) {
      win.focus();
    }
  };

  const goToPrivacy = () => {
    const win = window.open(Routes.Privacy, '_blank');
    if (win) {
      win.focus();
    }
  };

  return (
    <div ref={popoverAnchorRef}>
      <ReauthenticationConfirmation
        title={'Reauthentication Required'}
        content={
          <Typography variant="body2" paragraph>
            {
              'We are sad to have you leave us but if you must, please take a moment to consider the following.'
            }
            <br />
            <br />
            {
              'By entering your password you are agreeing to delete your account. This action is permanent and cannot be undone.'
            }
          </Typography>
        }
        open={confirmDeleteAccountModalOpen}
        onConfirmationComplete={handleDeleteAccountConfirmationApprove}
        onClose={handleDeleteAccountConfirmationDeny}
      />
      <Tooltip
        title={TOOLTIP_TEXT}
        placement="bottom-end"
        disableHoverListener={popoverOpen}
      >
        <Box>
          {firebase.auth().currentUser?.email}

          <S.ActionIcon
            edge="end"
            aria-label="account actions"
            onClick={togglePopoverOpen}
            buttonColor={color || theme.palette.primary.contrastText}
            className={className}
          >
            <AccountActionIcon style={{ fontSize: '1.75rem' }} />
          </S.ActionIcon>
        </Box>
      </Tooltip>
      {popoverAnchorEl && (
        <S.Popover
          TransitionComponent={Collapse}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={popoverOpen}
          anchorEl={popoverAnchorEl}
          onClose={handlePopoverClose}
        >
          <S.ButtonContainer aria-label="Account action menu buttons">
            {/* <S.Button
              onClick={onChangePasswordClick}
              variant={'text'}
              size={'small'}
              startIcon={<LockIcon />}
            >
              {'Change Password'}
            </S.Button>

            <S.ButtonDivider /> */}

            <S.Button
              onClick={goToTerms}
              variant={'text'}
              size={'small'}
              startIcon={<OpenInNewIcon />}
            >
              {'Terms of Service'}
            </S.Button>

            <S.Button
              onClick={goToPrivacy}
              variant={'text'}
              size={'small'}
              startIcon={<OpenInNewIcon />}
            >
              {'Privacy'}
            </S.Button>

            <S.ButtonDivider />

            <S.Button
              onClick={onLogoutClick}
              variant={'text'}
              size={'small'}
              startIcon={<LogoutIcon />}
            >
              {'Logout'}
            </S.Button>

            <S.Button
              onClick={onDeleteAccountClick}
              variant={'text'}
              size={'small'}
              startIcon={<DeleteIcon />}
            >
              {'Delete Account'}
            </S.Button>
          </S.ButtonContainer>
        </S.Popover>
      )}
    </div>
  );
}

export default AccountActionsMenu;
