import { useCallback } from 'react';
import { gql, useQuery, useLazyQuery, QueryLazyOptions } from '@apollo/client';
import { Catalog } from '../../types';
import { ALL_AD_RESOURCES } from '../../constants';

const GET_CATALOG_ADS = gql`
  query GetCatalogAdsWithMetrics(
    $catalogId: String!
    $adFirst: Int
    $adEndCursor: String
    $companyId: String
  ) {
    catalog(catalogId: $catalogId, companyID: $companyId) {
      catalogId
      adsWithMetrics(first: $adFirst, after: $adEndCursor) {
        edges {
          cursor
          node {
            title
            runsContinuously
            startsAfterUtcEpoch
            endsAfterUtcEpoch
            adTypesCatalogEntry {
              adTypesCatalogId
            }
            ${ALL_AD_RESOURCES}
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
      }
    }
  }
`;

type GetCatalogAdsWithMetricsResponse = {
  catalog: Catalog;
};
type GetCatalogAdsWithMetricsOperationVariables = {
  adFirst?: number;
  adEndCursor?: string;
  catalogId: string;
  companyId: string;
};
type GetCatalogAdsWithMetricsRequest =
  GetCatalogAdsWithMetricsOperationVariables;

export function useGetCatalogAdsWithMetrics({
  adFirst = 10,
  catalogId,
  adEndCursor,
  companyId,
}: GetCatalogAdsWithMetricsRequest) {
  return useQuery<
    GetCatalogAdsWithMetricsResponse,
    GetCatalogAdsWithMetricsOperationVariables
  >(GET_CATALOG_ADS, {
    variables: { adFirst, catalogId, adEndCursor, companyId },
  });
}

export function useLazyGetCatalogAdsWithMetrics() {
  const [execute, result] = useLazyQuery<
    GetCatalogAdsWithMetricsResponse,
    GetCatalogAdsWithMetricsOperationVariables
  >(GET_CATALOG_ADS);

  const createVariables = useCallback<
    (
      a: GetCatalogAdsWithMetricsOperationVariables
    ) => QueryLazyOptions<GetCatalogAdsWithMetricsOperationVariables>
  >(
    ({ catalogId, adFirst = 10, adEndCursor, companyId }) => ({
      variables: { adFirst, catalogId, adEndCursor, companyId },
    }),
    []
  );

  return {
    execute,
    createVariables,
    ...result,
  };
}
