import styled from "styled-components";

import withTheme from '@material-ui/core/styles/withTheme';
import MuiGrid from '@material-ui/core/Grid'

export const Grid = withTheme(
    styled(MuiGrid)`
      position: absolute;
      display: flex;
      bottom: 0px;
      margin-top: ${props => props.theme.spacing(1)}px;
      margin-bottom: ${props => props.theme.spacing(1)}px;
      padding-left: ${props => props.theme.spacing(1)}px;
      padding-right: ${props => props.theme.spacing(1)}px;
      color: ${props => props.theme.palette.text.primary};
      .MuiTypography-root {
        color: ${props => props.theme.palette.text.primary};
      }
    `
);