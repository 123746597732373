import styled from 'styled-components';

import withTheme from '@material-ui/core/styles/withTheme';
import Container from '@material-ui/core/Container';

/**
 * App Main Content
 */
export const AppMainContainer = withTheme(
  styled(Container)`
    background-color: ${(props) => props.theme.palette.background.paper};
    position: relative;
    padding: ${(props) => props.theme.spacing(2)}px;
  `
);
