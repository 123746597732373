import { gql, useQuery, useLazyQuery, QueryLazyOptions } from '@apollo/client';
import { ALL_AD_TYPES_CATALOG_ENTRY_FIELDS } from '../../constants';
import { AdTypesCatalogEntry, Catalog, AdCategoryGroup } from '../../types';
import { useCallback } from 'react';

export const GET_CATALOG_AND_AD_TYPES_CATALOG_QUERY = gql`
    query GetCatalogsAndAdTypesCatalog($companyID: String) {
      catalogs(companyID: $companyID) {
        catalogId
        displayName
        canDelete
      }
      adTypesCatalog {
        ${ALL_AD_TYPES_CATALOG_ENTRY_FIELDS}
      }
      adCategoryGroups {
        name
        categories {
          id
          name
        }
      }
    }
  `;

type GetCatalogsWithActiveAdsAndAdTypesCatalogResponse = {
  catalogs: Catalog[];
  adTypesCatalog: AdTypesCatalogEntry[];
  adCategoryGroups: AdCategoryGroup[];
};

type GetCatalogsAndAdTypesCatalogOperationVariables = {
  companyID: string;
};

export function useGetCatalogsAndAdTypesCatalog({
  companyID,
}: GetCatalogsAndAdTypesCatalogOperationVariables) {
  return useQuery<
    GetCatalogsWithActiveAdsAndAdTypesCatalogResponse,
    GetCatalogsAndAdTypesCatalogOperationVariables
  >(GET_CATALOG_AND_AD_TYPES_CATALOG_QUERY, { variables: { companyID } });
}

export function useLazyGetCatalogsAndAdTypesCatalog() {
  const [execute, result] = useLazyQuery<
    GetCatalogsWithActiveAdsAndAdTypesCatalogResponse,
    GetCatalogsAndAdTypesCatalogOperationVariables
  >(GET_CATALOG_AND_AD_TYPES_CATALOG_QUERY);

  const createVariables = useCallback<
    (
      a: GetCatalogsAndAdTypesCatalogOperationVariables
    ) => QueryLazyOptions<GetCatalogsAndAdTypesCatalogOperationVariables>
  >(
    ({ companyID }) => ({
      variables: { companyID },
    }),
    []
  );

  return {
    execute,
    createVariables,
    ...result,
  };
}
