import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import * as S from './LoadingIndicator.styled';

export function LoadingIndicator() {
  return (
    <S.LoadingContainer>
      <Box>
        <Typography variant="h5">Loading</Typography>

        <S.FoldingCube>
          <S.Cube />

          <S.Cube2 />

          <S.Cube4 />

          <S.Cube3 />
        </S.FoldingCube>
      </Box>
    </S.LoadingContainer>
  );
}
