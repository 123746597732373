export const isObjectEmpty = (object: any) =>
  Object.values(object || {}).every((x) => !x || x === '');

const minBy = <ObjectType extends object, ObjectKey extends keyof ObjectType>(
  objects: ObjectType[],
  prop: ObjectKey
) => {
  const objs = objects || [];
  if (objs.length === 0 || !objs[0].hasOwnProperty(prop)) {
    return undefined;
  }
  return objs.reduce((prev, current) => {
    return prev[prop] < current[prop] ? prev : current;
  });
};

export const minValueBy = <
  ObjectType extends object,
  ObjectKey extends keyof ObjectType
>(
  objects: ObjectType[],
  prop: ObjectKey
) => {
  return (minBy(objects, prop) || ({} as ObjectType))[prop];
};

export const maxBy = <
  ObjectType extends object,
  ObjectKey extends keyof ObjectType
>(
  objects: ObjectType[],
  prop: ObjectKey
) => {
  const objs = objects || [];
  if (objs.length === 0 || !objs[0].hasOwnProperty(prop)) {
    return undefined;
  }
  return objs.reduce((prev, current) => {
    return prev[prop] > current[prop] ? prev : current;
  });
};

export const maxValueBy = <
  ObjectType extends object,
  ObjectKey extends keyof ObjectType
>(
  objects: ObjectType[],
  prop: ObjectKey
) => {
  return (maxBy(objects, prop) || ({} as ObjectType))[prop];
};

export const shallowClone = <ObjectType extends object>(object: ObjectType) => {
  return Object.assign({}, object) as ObjectType;
};

export const deepClone = <ObjectType extends object>(object: ObjectType) => {
  return JSON.parse(JSON.stringify(object)) as ObjectType;
};
