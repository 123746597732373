import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Slide from '@material-ui/core/Slide/Slide';
import { useObject } from '../../../hooks';
import SnackBarAlert from './SnackBarAlert';
import {
  AppAlertContext,
  DEFAULT_APP_ALERT_STATE,
} from './SnackBarAlert.hooks';
import { SnackbarCloseReason } from '@material-ui/core/Snackbar/Snackbar';

function AppAlertContextProvider({ children }: React.PropsWithChildren<{}>) {
  const [appAlertState] = useObject(DEFAULT_APP_ALERT_STATE);
  const {
    value: { onClose, ...appAlertProps },
    setValue,
  } = appAlertState;

  function DownSlideTransition(props: TransitionProps) {
    return <Slide {...props} direction="down" />;
  }

  function onCloseHandler(
    event: React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason | undefined
  ) {
    if (reason && reason !== 'timeout') {
      event.preventDefault();
    }
    onClose && onClose(event, reason);
    setValue(DEFAULT_APP_ALERT_STATE);
  }

  return (
    <React.Fragment>
      <AppAlertContext.Provider value={appAlertState}>
        {children}
      </AppAlertContext.Provider>
      <SnackBarAlert
        TransitionComponent={
          appAlertProps.TransitionComponent || DownSlideTransition
        }
        onClose={onCloseHandler}
        {...appAlertProps}
      />
    </React.Fragment>
  );
}

export const withAppAlert = function <P extends object>(
  Component: React.ComponentType<P>
) {
  const WithAppAlert = React.forwardRef<React.ComponentType<P>, P>(
    function WithAppAlert(props, ref) {
      return (
        <AppAlertContextProvider>
          <Component {...(props as P)} ref={ref} />
        </AppAlertContextProvider>
      );
    }
  );
  return WithAppAlert;
};
