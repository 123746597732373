import styled from 'styled-components';
import Container from '@material-ui/core/Container/Container';

export const EmptyStateContainer = styled(Container)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(2, 0)};
  margin: 0 auto;
`;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(2, 0)};
  margin: ${({ theme }) => theme.spacing(0, 2)};
  text-align: center;
`;

export const MessageContainer = styled.div`
  position: relative;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(2, 0)};
  margin: ${({ theme }) => theme.spacing(0, 2)};
  text-align: center;
`;

export const ActionsContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(3, 0)};
  display: flex;
  justify-content: center;
`;

export const IconContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing(0, 2)};
  padding ${({ theme }) => theme.spacing(2, 0)};
`;
