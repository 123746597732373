import { UserTrace } from '../../api/userTracing.api';
import { ApiCallStatus } from '../shared/shared.constants';

export const NAME = 'userTracing';

export enum UserTraceApiRequestTypes {
	CreateUserTrace = 'createUserTrace',
  UpdatePageVisited = 'updatePageVisited',
  UpdateHTTPInfo = 'updateHTTPInfo',
}

export type UserTraceApiRequests = {
	[UserTraceApiRequestTypes.CreateUserTrace]: {
    status: ApiCallStatus;
		userTrace?: UserTrace;
    error?: any;
  };
  [UserTraceApiRequestTypes.UpdatePageVisited]: {
    status: ApiCallStatus;
    error?: any;
  };
  [UserTraceApiRequestTypes.UpdateHTTPInfo]: {
    status: ApiCallStatus;
    error?: any;
  };
};

export type UserTraceStatusUpdates<P extends keyof UserTraceApiRequests> = {
  requestType: P;
  update: UserTraceApiRequests[P];
};

export function createUpdate<P extends keyof UserTraceApiRequests>(
  requestType: P,
  update: UserTraceApiRequests[P]
): UserTraceStatusUpdates<P> {
  return {
    requestType,
    update,
  };
}
