import { css, SimpleInterpolation } from 'styled-components';

export function calcInterpolation(
  minScreen: number,
  minValue: number,
  maxScreen: number,
  maxValue: number,
  unit: 'vw' | 'px' | 'rem' | 'em' = 'vw'
) {
  const a = (maxValue - minValue) / (maxScreen - minScreen);
  let b = minValue - a * minScreen;
  let sign = '+';
  if (b < 0) {
    sign = '-';
    b = Math.abs(b);
  }
  return `calc(${a * 100}vw ${sign} ${b}${unit})`;
}

export function interpolate(
  properties: string[],
  minScreen: number,
  minValue: number,
  maxScreen: number,
  maxValue: number,
  unit: 'vw' | 'px' | 'rem' | 'em' = 'px'
) {
  const startingSize = properties
    .map((it) => `${it}: ${minValue}${unit};`)
    .join('');
  const minMedia = properties
    .map(
      (it) =>
        `${it}: ${calcInterpolation(
          minScreen,
          minValue,
          maxScreen,
          maxValue,
          unit
        )};`
    )
    .join('');
  const maxMedia = properties
    .map((it) => `${it}: ${maxValue}${unit};`)
    .join('');
  return css`
      ${startingSize}

      @media screen and (min-width: ${minScreen}px) {
        ${minMedia}
      }

      @media screen and (min-width: ${maxScreen}px) {
        ${maxMedia}
      }
  `;
}

export const mediaQuery = (mediaQuerySignature: string) => {
  return (literals: TemplateStringsArray, ...args: SimpleInterpolation[]) => {
    return css`
      ${mediaQuerySignature} {
        ${css(literals, ...args)}
      }
    `.join('');
  };
};
