import React from 'react';
import { useSelector } from 'react-redux';
import * as ducks from '../../ducks';
import { ComponentLoader } from '../common/ComponentLoader/ComponentLoader';

interface LoadingPageProps {}

export default function AuthLoadingPage({
  children = [],
}: React.PropsWithChildren<LoadingPageProps>) {
  const authPreProcessingCompleted = useSelector(
    ducks.auth.selectors.getAuthPreProcessingCompleted
  );
  return (
    <ComponentLoader loaded={authPreProcessingCompleted}>
      {authPreProcessingCompleted ? children : null}
    </ComponentLoader>
  );
}
