import styled, { keyframes } from 'styled-components';

export const FoldCubeAngleAnimation = keyframes`
  0%,
    10% {
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }
    25%,
    75% {
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }
    90%,
    100% {
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
`;

export const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const FoldingCube = styled.div`
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotateZ(45deg);
`;

export const Cube = styled.div`
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.palette.primary.main};
    animation: ${FoldCubeAngleAnimation} 2.4s infinite linear both;
    transform-origin: 100% 100%;
  }
`;

export const Cube2 = styled(Cube)`
  transform: scale(1.1) rotateZ(90deg);
  &::before {
    animation-delay: 0.3s;
  }
`;

export const Cube3 = styled(Cube)`
  transform: scale(1.1) rotateZ(180deg);
  &::before {
    animation-delay: 0.6s;
  }
`;

export const Cube4 = styled(Cube)`
  transform: scale(1.1) rotateZ(270deg);
  &::before {
    animation-delay: 0.9s;
  }
`;
