enum Routes {
  Landing = '/',
  LogIn = '/login',
  SignUp = '/signup',
  PasswordReset = '/password-reset',
  SignUpFlow = '/i/flow/signup',
  SignUpFlowCompany = '/i/flow/signup/company',
  SignUpFlowJoinTeam = '/i/flow/signup/join-team',
  Privacy = 'https://www.swaymee.com/privacy',
  Terms = 'https://www.swaymee.com/terms',
  AppRoot = '/app',
  Team = '/app/team',
  AdCampaign = '/app/ad-campaign',
  Analytics = '/app/analytics',
  MyBusiness = '/app/my-business',
  Billing = '/app/billing',
  Settings = '/app/settings',
  AuthUserManagement = '/__/firebaseauth/action',
}

export const routePathKeys = Object.freeze({
  [Routes.Landing]: 'LANDING',
  [Routes.LogIn]: 'LOGIN',
  [Routes.SignUp]: 'SIGNUP',
  [Routes.SignUpFlow]: 'SIGNUP_FLOW',
  [Routes.SignUpFlowCompany]: 'SIGNUP_FLOW_COMPANY',
  [Routes.SignUpFlowJoinTeam]: 'SIGNUP_FLOW_JOIN_TEAM',
  [Routes.Privacy]: 'PRIVACY',
  [Routes.Terms]: 'TERMS',
  [Routes.Team]: 'TEAM',
  [Routes.AppRoot]: 'ARRP_ROOT',
  [Routes.AdCampaign]: 'AD_CAMPAIGN',
  [Routes.Analytics]: 'ANALYTICS',
  [Routes.MyBusiness]: 'MY_BUSINESS',
  [Routes.Billing]: 'BILLING',
  [Routes.Settings]: 'SETTINGS',
  [Routes.AuthUserManagement]: 'AUTH_USER_MANAGEMENT',
  [Routes.PasswordReset]: 'PASSWORD_RESET',
});

export default Routes;
