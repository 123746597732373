import React from 'react';
import Routes from '../../routes';
import { Link as RouterLink } from 'react-router-dom';

import Link from '@material-ui/core/Link';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

export type LegalCaptions = 'privacy' | 'terms';

const LegalCaptions2Route: Record<LegalCaptions, Routes> = {
  privacy: Routes.Privacy,
  terms: Routes.Terms,
};
Object.freeze(LegalCaptions2Route);

/**
 * Component for public facing (non-user-specific) pages containing legal content
 */
export interface LegalsProps extends Pick<TypographyProps, 'variant'> {
  caption: LegalCaptions;
  newTab?: boolean;
}
export const Legal = ({
  caption,
  variant = 'subtitle1',
  newTab = true,
}: LegalsProps): JSX.Element => {
  return (
    <Typography variant={variant} align="center">
      <RouterLink
        to={LegalCaptions2Route[caption]}
        target={newTab ? '_blank' : undefined}
      >
        <Link component="button" variant={variant} underline="hover">
          {' '}
          {caption}{' '}
        </Link>
      </RouterLink>
    </Typography>
  );
};

export default Legal;
