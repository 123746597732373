import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import * as S from './LegalFooter.styled';
import Legal, { LegalCaptions } from './Legal';
import TextBullet from '../common/styled/TextBullet.styled';

export interface LegalFooterProps {
  captions: LegalCaptions[];
}

export default ({ captions }: LegalFooterProps) => {
  const theme = useTheme();
  return (
    <S.Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      wrap="nowrap"
      spacing={1}
    >
      {captions.map((cap, index) => {
        return (
          <React.Fragment key={`legalFooter-${index}`}>
            <Grid item>
              <Legal caption={cap} />
            </Grid>
            {index + 1 < captions.length ? (
              <Grid item>
                <TextBullet theme={theme} themeFontSizeNumber={0.7} />
              </Grid>
            ) : null}
          </React.Fragment>
        );
      })}
    </S.Grid>
  );
};
