import React from 'react';
import SwayMeeAppSkeleton from '../SwayMeeAppSkeleton/SwayMeeAppSkeleton';
import { Switch, Route } from 'react-router-dom';
import Routes from '../../routes';
import NoMatch from '../common/NoMatch';
import { ComponentLoader } from '../common/ComponentLoader/ComponentLoader';

const LazyAdCampaign = React.lazy(() => import('../AdCampaign'));
const LazyTeam = React.lazy(() => import('../Team/Team'));
const LazyBilling = React.lazy(() => import('../Billing'));
const LazyBusinessInfo = React.lazy(() => import('../BusinessInfo'));
const LazyAnalytics = React.lazy(() => import('../Analytics'));

export default function SwayMeeAppRoot() {
  return (
    <SwayMeeAppSkeleton>
      <React.Suspense fallback={<ComponentLoader loaded={false} />}>
        <Switch>
          <Route path={Routes.AdCampaign} component={LazyAdCampaign} />
          <Route path={Routes.Team} component={LazyTeam} />
          <Route path={Routes.Analytics} component={LazyAnalytics} />
          <Route path={Routes.MyBusiness} component={LazyBusinessInfo} />
          <Route path={Routes.Billing} component={LazyBilling} />
          <Route component={NoMatch} />
        </Switch>
      </React.Suspense>
    </SwayMeeAppSkeleton>
  );
}
