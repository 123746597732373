import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import saga from './rootSaga';
import createSagaMiddleware from 'redux-saga';
import createRootReducer from './rootReducer';
import { createBrowserHistory } from 'history';
import * as ducks from '../ducks/ducks';
import { routerMiddleware } from 'connected-react-router';
import { UserProfile } from '../api/types';
import { UserTrace } from '../api/userTracing.api';
import { LocalStorageKey } from '../utils/LocalStorage';
import { BillingAccount } from '../api/billing.api';

const history = createBrowserHistory();

const rootReducer = createRootReducer(history);

const sagaMiddleware = createSagaMiddleware();

const loggerMiddleware = createLogger({
  colors: {},
  collapsed: () => true,
});

let middlewareList = [sagaMiddleware, routerMiddleware(history)];

if (process.env.NODE_ENV !== 'production') {
  middlewareList.push(loggerMiddleware);
}

let middleware = applyMiddleware(...middlewareList);

if (process.env.NODE_ENV !== 'production')
  middleware = composeWithDevTools(middleware);

const store = createStore(rootReducer, middleware);

sagaMiddleware.run(saga);

store.dispatch(ducks.shared.actions.initialized());

export { store };
export { history };

export const LocalStorageKeys = {
  INITIAL_USER_PROFILE:
    'swaymee.business.InitialUserProfile' as LocalStorageKey<UserProfile>,
  INITIAL_USER_TRACE:
    'swaymee.business.InitialUserTrace' as LocalStorageKey<UserTrace>,
  INITIAL_USER_PROFILE_IMG:
    'swaymee.business.InitialUserProfileImageDataUrl' as LocalStorageKey<string>,
  INITIAL_USER_BILLING_ACCOUNT:
    'swaymee.business.InitialUserBillingAccount' as LocalStorageKey<BillingAccount>,
};
