import { useCallback } from 'react';
import { gql, useQuery, useLazyQuery, QueryLazyOptions } from '@apollo/client';
import { ALL_AD_FIELDS } from '../../constants';
import { Catalog } from '../../types';

const GET_CATALOG_ARCHIVED_ADS = gql`
    query GetCatalogArchivedAds($catalogId: String!, $adFirst: Int, $adEndCursor: String, $companyID: String){
      catalog(catalogId: $catalogId, companyID: $companyID) {
        catalogId
        displayName
        archivedAds(first: $adFirst, after: $adEndCursor) {
          edges {
            cursor
            node {
              ${ALL_AD_FIELDS}
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    }
  `;

type GetCatalogArchivedAdsResponse = {
  catalog: Catalog;
};
type GetCatalogArchivedAdsOperationVariables = {
  adFirst?: number;
  adEndCursor?: string;
  catalogId: string;
  companyID: string;
};
type GetCatalogAdsRequest = GetCatalogArchivedAdsOperationVariables;

export function useGetCatalogArchivedAds({
  adFirst = 10,
  catalogId,
  adEndCursor,
  companyID,
}: GetCatalogAdsRequest) {
  return useQuery<
    GetCatalogArchivedAdsResponse,
    GetCatalogArchivedAdsOperationVariables
  >(GET_CATALOG_ARCHIVED_ADS, {
    variables: { adFirst, catalogId, adEndCursor, companyID },
  });
}

export function useLazyGetCatalogArchivedAds() {
  const [execute, result] = useLazyQuery<
    GetCatalogArchivedAdsResponse,
    GetCatalogArchivedAdsOperationVariables
  >(GET_CATALOG_ARCHIVED_ADS);

  const createVariables = useCallback<
    (
      a: GetCatalogArchivedAdsOperationVariables
    ) => QueryLazyOptions<GetCatalogArchivedAdsOperationVariables>
  >(
    ({ catalogId, adFirst = 10, adEndCursor, companyID }) => ({
      variables: { adFirst, catalogId, adEndCursor, companyID },
    }),
    []
  );

  return {
    execute,
    createVariables,
    ...result,
  };
}
