import { gql, useQuery, useLazyQuery, QueryLazyOptions } from '@apollo/client';
import { Catalog } from '../../types';
import { useCallback } from 'react';

export const GET_CATALOGS_QUERY = gql`
  query GetCatalogs($companyID: String) {
    catalogs(companyID: $companyID) {
      catalogId
      displayName
      canDelete
    }
  }
`;

type GetCatalogsResponse = {
  catalogs: Catalog[];
};

type GetCatalogsOperationVariables = {
  companyID: string;
};

export function useGetCatalogs({ companyID }: GetCatalogsOperationVariables) {
  return useQuery<GetCatalogsResponse, GetCatalogsOperationVariables>(
    GET_CATALOGS_QUERY,
    { variables: { companyID } }
  );
}

export function useLazyGetCatalogs() {
  const [execute, result] = useLazyQuery<
    GetCatalogsResponse,
    GetCatalogsOperationVariables
  >(GET_CATALOGS_QUERY);

  const createVariables = useCallback<
    (
      a: GetCatalogsOperationVariables
    ) => QueryLazyOptions<GetCatalogsOperationVariables>
  >(
    ({ companyID }) => ({
      variables: { companyID },
    }),
    []
  );

  return {
    execute,
    createVariables,
    ...result,
  };
}
