import { useCallback } from 'react';
import { gql, useQuery, useLazyQuery, QueryLazyOptions } from '@apollo/client';
import { ALL_AD_FIELDS } from '../../constants';
import { AdFeed } from '../../types';

type FeedTypes = 'activeAds' | 'archivedAds' | 'draftAds';

const createGetAdsGQL = (feedType: FeedTypes) => gql`
    query GetAds($adFirst: Int, $adEndCursor: String, $companyID: String) {
      ${feedType}(first: $adFirst, after: $adEndCursor, companyID: $companyID) {
        edges {
          cursor
          node {
            ${ALL_AD_FIELDS}
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
      }
    }
  `;

type GetAdsResponse<T = FeedTypes> = T extends 'activeAds'
  ? { activeAds: AdFeed }
  : T extends 'draftAds'
  ? { draftAds: AdFeed }
  : T extends 'archivedAds'
  ? { archivedAds: AdFeed }
  : never;

type GetCatalogActiveAdsOperationVariables = {
  adFirst?: number;
  adEndCursor?: string;
  companyID: string;
};
type GetAdsRequest = GetCatalogActiveAdsOperationVariables & {
  feedType: FeedTypes;
};

export function useGetAds({
  adFirst = 10,
  adEndCursor,
  feedType,
  companyID,
}: GetAdsRequest) {
  return useQuery<AdFeed, GetCatalogActiveAdsOperationVariables>(
    createGetAdsGQL(feedType),
    {
      variables: { adFirst, adEndCursor, companyID },
    }
  );
}

export function useLazyGetAds<T extends FeedTypes>(feedType: T) {
  const [execute, result] = useLazyQuery<
    GetAdsResponse<T>,
    GetCatalogActiveAdsOperationVariables
  >(createGetAdsGQL(feedType));

  const createVariables = useCallback<
    (
      a: GetCatalogActiveAdsOperationVariables
    ) => QueryLazyOptions<GetCatalogActiveAdsOperationVariables>
  >(
    ({ adFirst = 10, adEndCursor, companyID }) => ({
      variables: { adFirst, adEndCursor, companyID },
    }),
    []
  );

  return {
    execute,
    createVariables,
    ...result,
  };
}
