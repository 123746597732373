import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

const STOP_AD_MUTATION = gql`
  mutation StopAd($adId: String!, $companyID: String) {
    stopAd(adId: $adId, companyID: $companyID) {
      success
    }
  }
`;

type StopAdResponse = {
  stopAd: {
    success: boolean;
  };
};

type StopAdOperationVariables = {
  adId: string;
  companyID: string;
};

export function useStopAd() {
  const [execute, result] = useMutation<
    StopAdResponse,
    StopAdOperationVariables
  >(STOP_AD_MUTATION);

  const createVariables = useCallback<(adId: string, companyID: string) => any>(
    (adId: string, companyID: string) => ({
      variables: { adId, companyID },
    }),
    []
  );

  return {
    execute,
    createVariables,
    ...result,
  };
}
