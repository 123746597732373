import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Role } from '../../types';

const UPDATE_TEAM_MEMBER_ROLES_MUTATION = gql`
  mutation UpdateTeamMemberRoles(
    $teamMemberUserID: String!
    $companyID: String!
    $roles: [String!]!
  ) {
    updateTeamMemberRoles(
      teamMemberUserID: $teamMemberUserID
      companyID: $companyID
      roles: $roles
    ) {
      success
    }
  }
`;

export type UpdateTeamMemberRolesResponse = {
  updateTeamMemberRoles: {
    success: boolean;
  };
};

type UpdateTeamMemberRolesOperationVariables = {
  teamMemberUserID: string;
  companyID: string;
  roles: Role[];
};

export function useUpdateTeamMemberRoles() {
  const [execute, result] = useMutation<
    UpdateTeamMemberRolesResponse,
    UpdateTeamMemberRolesOperationVariables
  >(UPDATE_TEAM_MEMBER_ROLES_MUTATION);

  const createVariables = useCallback<
    (a: UpdateTeamMemberRolesOperationVariables) => any
  >(
    ({ companyID, roles, teamMemberUserID }) => ({
      variables: { companyID, roles, teamMemberUserID },
    }),
    []
  );

  return {
    execute,
    createVariables,
    ...result,
  };
}
