import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import withTheme from '@material-ui/core/styles/withTheme';

const TOOL_BAR_VERT_SPACING = 5;

/**
 * Under AppBar Material Content
 */
export const AppSkeletonBox = withTheme(styled(Box)`
  width: 100%;
  height: ${(props) =>
    `calc(100% - ${props.theme.spacing(TOOL_BAR_VERT_SPACING)}px)`};
  top: ${(props) => props.theme.spacing(TOOL_BAR_VERT_SPACING)}px;
  position: relative;
`);

export const AppSkeletonContent = withTheme(
  styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  `
);
