import firebase from 'firebase/app';
import 'firebase/auth';

const config = {
  apiKey: 'AIzaSyCddfAQEvTcDcbhfAH4tUNGlgpQfc7MWdw',
  authDomain: 'swaymeebusinessapp.firebaseapp.com',
  // databaseURL: 'https://pals-ffa03.firebaseio.com',
  projectId: 'swaymeebusinessapp',
};

firebase.initializeApp(config);
