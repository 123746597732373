import React from 'react';
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';

type ComponentLoaderProps = {
  loaded: boolean;
};

export function ComponentLoader({
  children,
  loaded,
}: React.PropsWithChildren<ComponentLoaderProps>) {
  return loaded ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <LoadingIndicator />
  );
}
