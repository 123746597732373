import { UAParser } from 'ua-parser-js';
import { isObjectEmpty } from './ObjectHelpers';

export interface Geolocation {
  city?: string;
  continent?: string;
  country?: string;
  countryCode?: string;
  ipName?: string;
  ipType?: string;
  isp?: string;
  lat?: string;
  lon?: string;
  org?: string;
  query?: string;
  region?: string;
  status: string;
}

const getUserLocationFromIP = async (): Promise<Geolocation> => {
  try {
    const response = await fetch('https://extreme-ip-lookup.com/json/');
    const data = await response.json();
    return data as Geolocation;
  } catch (err) {
    console.log(`Error occurred while retrieving ip location: ${err}`);
    return {} as Geolocation;
  }
};

export interface UserAgentInformation {
  ua?: string;
  browser?: string;
  device?: string;
  operatingSystem?: string;
  ip?: string;
  geolocation?: Geolocation;
}

export const getCurrentUserAgent = async (): Promise<UserAgentInformation> => {
  const userAgent: UserAgentInformation = {};
  try {
    // TODO: figure out IP
    // const geoloc = await getUserLocationFromIP();
    // if (!isObjectEmpty(geoloc)) {
    //   userAgent.geolocation = geoloc;
    //   userAgent.ip = geoloc.query;
    // } else {
    //   console.log(`ERROR: Unable to determine geolocation`);
    // }

    // userAgent Metadata
    const userAgentString = navigator.userAgent;
    userAgent.ua = userAgentString;
    if (!userAgentString) {
      console.log('UserAgent access not supported');
    } else {
      const parser = new UAParser(userAgentString);
      const browser = parser.getBrowser();
      const device = parser.getDevice();
      const os = parser.getOS();
      if (!isObjectEmpty(browser)) {
        userAgent.browser = `${browser.name}|${browser.version}`;
      }
      if (!isObjectEmpty(device)) {
        userAgent.device = `${device.vendor}|${device.type}|${device.model}`;
      }
      if (!isObjectEmpty(os)) {
        userAgent.operatingSystem = `${os.name}|${os.version}`;
      }
    }
  } catch (err) {
    console.log(
      `ERROR: Unable to construct user agent: ${JSON.stringify(err)} `
    );
  }
  return userAgent;
};
