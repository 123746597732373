import { combineReducers } from 'redux';
import * as actions from './auth.actions';
import { createReducer } from 'deox';
import produce from 'immer';
import { AuthApiRequestTypes, AuthApiRequests } from './auth.constants';
import { ApiCallStatus } from '../shared/shared.constants';

const authPreProcessingCompleted = createReducer(false, (handleAction) => [
  handleAction(
    actions.authPreProcessingComplete,
    produce((_) => true)
  ),
  handleAction(
    actions.authPreProcessingStart,
    produce((_) => false)
  ),
]);

const DEFAULT_API_REQUESTS: AuthApiRequests = {
  [AuthApiRequestTypes.CreateAccount]: {
    status: ApiCallStatus.Idle,
  },
  [AuthApiRequestTypes.LogOut]: {
    status: ApiCallStatus.Idle,
  },
  [AuthApiRequestTypes.DeleteAccount]: {
    status: ApiCallStatus.Idle,
  },
  [AuthApiRequestTypes.LogIn]: {
    status: ApiCallStatus.Idle,
  },
  [AuthApiRequestTypes.PasswordReset]: {
    status: ApiCallStatus.Idle,
  },
  [AuthApiRequestTypes.EmailVerification]: {
    status: ApiCallStatus.Idle,
  },
};

const apiRequests = createReducer(DEFAULT_API_REQUESTS, (handleAction) => [
  handleAction(actions.setApiStatus, (prevState, { payload }) =>
    produce(prevState, (draftState: AuthApiRequests) => {
      draftState[payload.requestType] = payload.update;
    })
  ),
]);

export default combineReducers({
  authPreProcessingCompleted,
  apiRequests,
});
