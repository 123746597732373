export const ALL_AD_RESOURCES = `
resources {
  __typename
  ... on PictureResources {
    picture {
      link
      url {
        readUrl
			  writeUrl
      }
    }
  }
  ... on CarouselResources {
    pictures {
      link
      url {
        readUrl
			  writeUrl
      }
    }
  }
  ... on GifResources {
    gif {
      link
      url {
        readUrl
			  writeUrl
      }
    }
  }
  ... on PollResources {
    pollQuestion
    pollOptions
  }
}
`;

export const ALL_AD_FIELDS = `
adTypesCatalogEntry {
  adTypesCatalogId
}
catalog {
  catalogId
}
adId
title
categories {
  id
  name
}
tags
link
caption
isDraft
runsContinuously
startsAfterUtcEpoch
endsAfterUtcEpoch
detailedDescription
flare
callToActionText
processingStatus
processingContentRiskTypes
${ALL_AD_RESOURCES}
`;

export const ALL_AD_TYPES_CATALOG_ENTRY_FIELDS = `
adTypesCatalogId
displayName
iconImageUrl
amount
currency
`;

const SELECTED_COMPANY_MINUS_TEAM = `
companyID
businessInfo {
  companyName
  businessAlias
  yearEstablished
  industry
  homepage
}
verification {
  status
  filename
  result
}
logo {
  writeUrl
  readUrl
}
permissions {
  isOwner
  canEditPromotions
  canStopPromotions
  canEditBilling
  canDeleteBilling
  canEditCatalog
  canDeleteCatalog
  canEditCompany
  canManageTeam
}
`;

export const ALL_SELECTED_COMPANY = `
${SELECTED_COMPANY_MINUS_TEAM}
teamMembers {
  userId
  email
  companyRoles
}
`;

export const ALL_SELECTED_COMPANY_MINUS_TEAM_MEMBERS =
  SELECTED_COMPANY_MINUS_TEAM;

export const ALL_USER_PROFILE_MINUS_TEAM_MEMBERS = `
userId
email
onboarded
selectedCompany {
  ${ALL_SELECTED_COMPANY_MINUS_TEAM_MEMBERS}
}
`;

export const ALL_USER_PROFILE = `
userId
email
onboarded
selectedCompany {
  ${ALL_SELECTED_COMPANY}
}
`;
