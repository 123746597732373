import { combineReducers } from 'redux';
import * as ducks from '../ducks';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

const { auth, shared, billing, userProfile, userTracing } = ducks;

function createRootReducer(history: History) {
  return combineReducers({
    [shared.constants.NAME]: shared.reducer,
    [auth.constants.NAME]: auth.reducer,
    [userProfile.constants.NAME]: userProfile.reducer,
    [userTracing.constants.NAME]: userTracing.reducer,
    [billing.constants.NAME]: billing.reducer,
    router: connectRouter(history),
  });
}

export type SwayMeeState = ReturnType<ReturnType<typeof createRootReducer>>;

export default createRootReducer;
