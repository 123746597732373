import { useCallback } from 'react';
import { gql, useQuery, useLazyQuery, QueryLazyOptions } from '@apollo/client';
import { ALL_AD_FIELDS } from '../../constants';
import { Catalog } from '../../types';

const GET_CATALOG_DRAFT_ADS = gql`
    query GetCatalogDraftAds($catalogId: String!, $adFirst: Int, $adEndCursor: String, $companyID: String){
      catalog(catalogId: $catalogId, companyID: $companyID) {
        catalogId
        displayName
        draftAds(first: $adFirst, after: $adEndCursor) {
          edges {
            cursor
            node {
              ${ALL_AD_FIELDS}
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    }
  `;

type GetCatalogDraftAdsResponse = {
  catalog: Catalog;
};
type GetCatalogDraftAdsOperationVariables = {
  adFirst?: number;
  adEndCursor?: string;
  catalogId: string;
  companyID: string;
};
type GetCatalogAdsRequest = GetCatalogDraftAdsOperationVariables;

export function useGetCatalogDraftAds({
  adFirst = 10,
  catalogId,
  adEndCursor,
  companyID,
}: GetCatalogAdsRequest) {
  return useQuery<
    GetCatalogDraftAdsResponse,
    GetCatalogDraftAdsOperationVariables
  >(GET_CATALOG_DRAFT_ADS, {
    variables: { adFirst, catalogId, adEndCursor, companyID },
  });
}

export function useLazyGetCatalogDraftAds() {
  const [execute, result] = useLazyQuery<
    GetCatalogDraftAdsResponse,
    GetCatalogDraftAdsOperationVariables
  >(GET_CATALOG_DRAFT_ADS);

  const createVariables = useCallback<
    (
      a: GetCatalogDraftAdsOperationVariables
    ) => QueryLazyOptions<GetCatalogDraftAdsOperationVariables>
  >(
    ({ catalogId, adFirst = 10, adEndCursor, companyID }) => ({
      variables: { adFirst, catalogId, adEndCursor, companyID },
    }),
    []
  );

  return {
    execute,
    createVariables,
    ...result,
  };
}
