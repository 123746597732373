import { combineReducers } from 'redux';
import * as actions from './userProfile.actions';
import { createReducer } from 'deox';
import { Types } from '../../api';
import * as authActions from '../auth/auth.actions';
import {
  UserProfileApiRequestTypes,
  UserProfileApiRequests,
} from './userProfile.constants';
import { ApiCallStatus } from '../shared/shared.constants';
import produce from 'immer';
import { AuthApiRequestTypes } from '../auth/auth.constants';

export const NULL_USER_PROFILE: Types.UserProfile = {
  __typename: 'UserProfile',
  userId: 'NULL_USER_ID',
  email: '',
  onboarded: false,
  selectableCompanies: [],
};

const userProfile = createReducer(NULL_USER_PROFILE, (handleAction) => [
  handleAction(
    [actions.fetchUserProfileSuccess, actions.createUserProfileSuccess],
    (_, { payload }) =>
      payload.__typename === 'UserProfileNotFound'
        ? NULL_USER_PROFILE
        : (payload as Types.UserProfile)
  ),
  handleAction(actions.unsetSelectedCompany, (prevState) =>
    produce(prevState, (draft) => {
      draft.selectedCompany = undefined;
    })
  ),
  handleAction(
    [actions.fetchUserProfileFailure, actions.createUserProfileFailure],
    () => NULL_USER_PROFILE
  ),
  handleAction(authActions.setApiStatus, (prevState, { payload }) =>
    produce(prevState, () => {
      if (
        payload.requestType === AuthApiRequestTypes.DeleteAccount ||
        payload.requestType === AuthApiRequestTypes.LogOut
      ) {
        if (payload.update.status === ApiCallStatus.Success) {
          return NULL_USER_PROFILE;
        }
      }
    })
  ),
  handleAction(actions.setApiStatus, (prevState, { payload }) =>
    produce(prevState, () => {
      if (
        payload.requestType === UserProfileApiRequestTypes.CreateUserProfile
      ) {
        if (payload.update.status === ApiCallStatus.Success) {
          return (
            payload.update as UserProfileApiRequests[UserProfileApiRequestTypes.CreateUserProfile]
          ).userProfile;
        } else if (payload.update.status === ApiCallStatus.Error) {
          return NULL_USER_PROFILE;
        }
      }
    })
  ),
  handleAction(
    actions.updateUserProfile,
    (prevState, { payload: { onboarded } }) =>
      produce(prevState, (draft) => {
        if (onboarded !== undefined) {
          draft.onboarded = onboarded;
        }
      })
  ),
  handleAction(actions.setSelectedCompany, (prevState, { payload }) =>
    produce(prevState, (draft) => {
      draft.selectedCompany = payload;
    })
  ),
  handleAction(
    actions.updateCompany,
    (
      prevState,
      { payload: { businessInfo, verification, teamMembers, logo } }
    ) =>
      produce(prevState, (draft) => {
        if (businessInfo) {
          draft.selectedCompany!.businessInfo = businessInfo;
        }
        if (verification) {
          draft.selectedCompany!.verification = verification;
        }
        if (logo) {
          draft.selectedCompany!.logo = logo;
        }
        if (teamMembers) {
          draft.selectedCompany!.teamMembers = teamMembers;
        }
      })
  ),
]);

const DEFAULT_API_REQUESTS: UserProfileApiRequests = {
  [UserProfileApiRequestTypes.CreateUserProfile]: {
    status: ApiCallStatus.Idle,
  },
  [UserProfileApiRequestTypes.UpdateUserOnboarded]: {
    status: ApiCallStatus.Idle,
  },
  [UserProfileApiRequestTypes.UpdateBusinessInfo]: {
    status: ApiCallStatus.Idle,
  },
  [UserProfileApiRequestTypes.UploadVerificationDocument]: {
    status: ApiCallStatus.Idle,
  },
  [UserProfileApiRequestTypes.UploadBusinessUserProfileImage]: {
    status: ApiCallStatus.Idle,
  },
};

const apiRequests = createReducer(DEFAULT_API_REQUESTS, (handleAction) => [
  handleAction(actions.setApiStatus, (prevState, { payload }) =>
    produce(prevState, (draftState: UserProfileApiRequests) => {
      draftState[payload.requestType] = payload.update;
    })
  ),
]);

export default combineReducers({
  userProfile,
  apiRequests,
});
