import isHexColor from 'validator/lib/isHexColor';

export const noop = () => {};

export const not = (x: boolean) => !x;

export function rgba2hex(orig: string) {
  if (!isHexColor(orig)) {
    return orig;
  }
  let a: number;
  const rgb = orig
    .replace(/\s/g, '')
    .match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);
  const alpha = ((rgb && rgb[4]) || '').trim();
  let hex = orig;

  if (!(rgb === null || rgb === undefined)) {
    hex =
      (parseFloat(rgb[1]) | (1 << 8)).toString(16).slice(1) +
      (parseFloat(rgb[2]) | (1 << 8)).toString(16).slice(1) +
      (parseFloat(rgb[3]) | (1 << 8)).toString(16).slice(1);
  }

  if (alpha !== '') {
    a = parseFloat(alpha);
  } else {
    a = 0o1;
  }
  // multiply before convert to HEX
  hex = hex + ((a * 255) | (1 << 8)).toString(16).slice(1);
  return '#' + hex;
}

export function hexToRgba(hex: string, alpha: number = 1.0) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const boundedAlpha = Math.max(Math.min(alpha, 1.0), 0.0);

  if (result) {
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);
    return `rgba(${r},${g},${b},${boundedAlpha})`;
  }
  return null;
}
