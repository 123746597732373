import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography/Typography';

import * as S from './EmptyState.styled';
import { useTheme } from '@material-ui/core';

export interface EmptyStateProps {
  icon?: React.ReactNode;
  title: string;
  message: string | React.ReactNode;
  className?: string;
}

export default function EmptyState({
  icon,
  title,
  message,
  children,
  className,
}: React.PropsWithChildren<EmptyStateProps>) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <S.EmptyStateContainer maxWidth="md" className={className}>
      {icon && matches && <S.IconContainer>{icon}</S.IconContainer>}
      <S.TitleContainer>
        <Typography variant="h4">{title}</Typography>
      </S.TitleContainer>
      <S.MessageContainer>
        <Typography component="div" variant="body1">
          {message}
        </Typography>
      </S.MessageContainer>
      <S.ActionsContainer>{children}</S.ActionsContainer>
    </S.EmptyStateContainer>
  );
}
