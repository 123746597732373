import React from 'react';
import { useSelector } from 'react-redux';
import SwayMeeAppBar from './SwayMeeAppBar';
import SwayMeeSideMenu from './SwayMeeAppSideMenu';
import { AppMainContainer } from './SwayMeeAppMain.styled';
import * as S from './SwayMeeAppSkeleton.styled';
import * as ducks from '../../ducks';

export interface SwayMeeAppSkeletonProps {}

export default function SwayMeeAppSkeleton({
  children = null,
}: React.PropsWithChildren<SwayMeeAppSkeletonProps>) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const userProfile = useSelector(ducks.userProfile.selectors.getUserProfile);
  const { companyName = 'Fake Business Name Inc.' } =
    userProfile.selectedCompany?.businessInfo ?? {};

  const handleMenuClick = () => {
    setMobileOpen((mobileOpen) => !mobileOpen);
  };

  return (
    <S.AppSkeletonBox>
      <SwayMeeAppBar title={companyName} handleMenuClick={handleMenuClick} />

      <S.AppSkeletonContent>
        <SwayMeeSideMenu
          isOpen={mobileOpen}
          onClose={() => setMobileOpen(false)}
        />

        {children && (
          <AppMainContainer component="main" maxWidth={false}>
            {children}
          </AppMainContainer>
        )}
      </S.AppSkeletonContent>
    </S.AppSkeletonBox>
  );
}
