import client from './common.api';
import { gql } from '@apollo/client';
import { AdsServiceError } from './ads.errors';
import moment from 'moment';
import {
  Ad,
  AdResources,
  CarouselResources,
  GifResources,
  PictureResources,
  PollResources,
  ResourceUrl,
} from './types';

export { ad as hooks } from './hooks';

export function isPictureResource(
  value: AdResources
): value is PictureResources {
  return value.hasOwnProperty('picture');
}
export function isCarouselResource(
  value: AdResources
): value is CarouselResources {
  return value.hasOwnProperty('pictures');
}
export function isGifResource(value: AdResources): value is GifResources {
  return value.hasOwnProperty('gif');
}
export function isPollResource(value: AdResources): value is PollResources {
  return (
    value.hasOwnProperty('pollQuestion') && value.hasOwnProperty('pollOptions')
  );
}

type MutationResponse = {
  code: number;
  success: boolean;
  message: string;
};

export type LinkErrorIndex = {
  index: number;
};

export type LinkErrorContext = {
  errors: LinkErrorIndex[];
};

export type CreateAdMutationResponse = MutationResponse & {
  ad: Ad;
  errorContext?: {
    errors: LinkErrorContext[];
  };
};

export type StopAdMutationResponse = MutationResponse & {
  ad: Ad;
};

export type DeleteDraftAdMutationResponse = MutationResponse & {
  ad: Ad;
};
export type UpdateAdMutationResponse = MutationResponse & {
  ad: Ad;
  errorContext?: {
    errors: { index: number }[];
  };
};
export type ConvertAdMutationResponse = MutationResponse & {
  ad: Ad;
  errorContext?: {
    errors: { index: number }[];
  };
};

export const ALL_AD_FIELDS = `
adTypesCatalogEntry {
  adTypesCatalogId
}
catalog {
  catalogId
}
adId
title
categories {
  id
  name
}
tags
link
caption
isDraft
runsContinuously
startsAfterUtcEpoch
endsAfterUtcEpoch
detailedDescription
flare
callToActionText
processingStatus
processingContentRiskTypes
resources {
  ... on PictureResources {
    picture {
      url {
        readUrl
        writeUrl
      }
      link
    }
  }
  ... on CarouselResources {
    pictures {
      url {
        readUrl
        writeUrl
      }
      link
    }
  }
  ... on GifResources {
    gif {
      url {
        readUrl
        writeUrl
      }
      link
    }
  }
  ... on PollResources {
    pollQuestion
    pollOptions
  }
}
`;

/**
 * CREATION
 */
export type CreateAdRequest = {
  companyID: string;
  catalogId: string;
  adTypeId: string;
  title: string;
  adCategoryIds: string[];
  tags: string[];
  link?: string;
  caption: string;
  isDraft: boolean;
  detailedDescription: string;
  flare: string;
  daysToRun: number;
  resources: AdResources;
  callToActionText?: string;
};

export async function createAd(request: CreateAdRequest): Promise<Ad | null> {
  type CreateAdMutation = {
    createAd: CreateAdMutationResponse;
  };
  const mutation = gql`
    mutation CreateAdMutation ($adInput: CreateAdInput!) {
      createAd(adInput: $adInput) {
				success
				message
        ad {
          ${ALL_AD_FIELDS}
        }
      }
    }
  `;
  const variables = {
    adInput: request,
  };
  const result = await client.mutate<CreateAdMutation>({
    mutation,
    variables,
  });
  const model = result.data?.createAd;
  if (!model?.success) {
    throw new AdsServiceError(model?.message);
  }
  return model?.ad ?? null;
}

export type CreateContinuousAdRequest = Omit<CreateAdRequest, 'daysToRun'>;

export async function createContinuousAd(
  request: CreateContinuousAdRequest
): Promise<Ad | null> {
  type CreateContinuousAdMutation = {
    createContinuousAd: CreateAdMutationResponse;
  };
  const mutation = gql`
    mutation CreateContinuousAdMutation ($adInput: CreateContinuousAdInput!) {
      createContinuousAd(adInput: $adInput) {
				success
				message
        ad {
          ${ALL_AD_FIELDS}
        }
      }
    }
  `;

  const variables = {
    adInput: request,
  };
  const result = await client.mutate<CreateContinuousAdMutation>({
    mutation,
    variables,
  });
  const model = result.data?.createContinuousAd;
  if (!model?.success) {
    throw new AdsServiceError(model?.message);
  }
  return model?.ad ?? null;
}

/**
 * UPDATES
 */

export type UpdateAdRequest = {
  companyID: string;
  adId: string;
  catalogId: string;
  adTypeId: string;
  title: string;
  adCategoryIds: string[];
  tags: string[];
  link?: string;
  caption: string;
  detailedDescription: string;
  daysAfterStartToEnd: number;
  isDraft: boolean;
  flare?: string;
  resources: AdResources;
  callToActionText?: string;
};

export async function updateAd(request: UpdateAdRequest): Promise<Ad | null> {
  type UpdateAdMutation = {
    updateAd: UpdateAdMutationResponse;
  };
  const mutation = gql`
    mutation UpdateAdMutation ($adInput: UpdateAdInput!) {
      updateAd(adInput: $adInput) {
				success
				message
        ad {
          ${ALL_AD_FIELDS}
        }
      }
    }
  `;
  const variables = {
    adInput: request,
  };
  const result = await client.mutate<UpdateAdMutation>({
    mutation,
    variables,
  });
  const model = result.data?.updateAd;
  if (!model?.success) {
    throw new AdsServiceError(model?.message);
  }
  return model?.ad ?? null;
}

export type UpdateContinuousAdRequest = {
  companyID: string;
  adId: string;
  catalogId: string;
  adTypeId: string;
  title: string;
  adCategoryIds: string[];
  tags: string[];
  link?: string;
  caption: string;
  detailedDescription: string;
  isDraft: boolean;
  flare?: string;
  resources: AdResources;
  callToActionText?: string;
};

export async function updateContinuousAd(
  request: UpdateContinuousAdRequest
): Promise<Ad | null> {
  type UpdateContinuousAdMutation = {
    updateContinuousAd: UpdateAdMutationResponse;
  };
  const mutation = gql`
    mutation UpdateContinuousAdMutation ($adInput: UpdateContinuousAdInput!) {
      updateContinuousAd(adInput: $adInput) {
				success
				message
        ad {
          ${ALL_AD_FIELDS}
        }
      }
    }
  `;

  const variables = {
    adInput: request,
  };
  const result = await client.mutate<UpdateContinuousAdMutation>({
    mutation,
    variables,
  });
  const model = result.data?.updateContinuousAd;
  if (!model?.success) {
    throw new AdsServiceError(model?.message);
  }
  return model?.ad ?? null;
}

/**
 * CONVERTING
 */

export type ConvertAdToContinuousAdRequest = UpdateContinuousAdRequest;
export async function convertAdToContinuousAd(
  request: ConvertAdToContinuousAdRequest
): Promise<Ad | null> {
  type ConvertAdToContinuousAdMutation = {
    convertAdToContinuousAd: ConvertAdMutationResponse;
  };
  const mutation = gql`
    mutation ConvertAdToContinuousAdMutation ($adInput: ConvertAdToContinuousAdInput!) {
      convertAdToContinuousAd(adInput: $adInput) {
        ad {
          ${ALL_AD_FIELDS}
        }
      }
    }
  `;

  const variables = {
    adInput: request,
  };
  const result = await client.mutate<ConvertAdToContinuousAdMutation>({
    mutation,
    variables,
  });
  return result.data?.convertAdToContinuousAd.ad ?? null;
}

export type ConvertContinuousAdToAdRequest = {
  companyID: string;
  adId: string;
  catalogId: string;
  adTypeId: string;
  title: string;
  adCategoryIds: string[];
  tags: string[];
  link?: string;
  caption: string;
  detailedDescription: string;
  additionalDaysFromNowToRun: number;
  isDraft: boolean;
  flare?: string;
  resources: AdResources;
  callToActionText?: string;
};

export async function convertContinuousAdToAd(
  request: ConvertContinuousAdToAdRequest
): Promise<Ad | null> {
  type ConvertContinuousAdToAdMutation = {
    convertContinuousAdToAd: ConvertAdMutationResponse;
  };
  const mutation = gql`
    mutation ConvertContinuousAdToAdMutation ($adInput: ConvertContinuousAdToAdInput!) {
      convertContinuousAdToAd(adInput: $adInput) {
        ad {
          ${ALL_AD_FIELDS}
        }
      }
    }
  `;

  const variables = {
    adInput: request,
  };
  const result = await client.mutate<ConvertContinuousAdToAdMutation>({
    mutation,
    variables,
  });
  return result.data?.convertContinuousAdToAd.ad ?? null;
}

export type FileDetails = {
  type: string;
  name: string;
};

export function createImageResourceFilename(aspectRatio: string): string {
  return `${moment().valueOf()}__${aspectRatio.split(':').join('_')}`;
}

export function extractAspectRatioFromFile(storagePath: string): string {
  const split = storagePath.split('__');
  const [_, ratioWithExtensionEnd] = split;
  const split2 = ratioWithExtensionEnd.split('.');
  const [adjustedRatio] = split2;
  const ratio = adjustedRatio.split('_').join(':');
  return ratio;
}

export async function getAdResourcesPreSignedUrl(
  files: FileDetails[],
  companyID: string
): Promise<ResourceUrl[] | null> {
  type GetAdResourcesPreSignedUrlQuery = {
    getAdResourcesPreSignedUrls: ResourceUrl[];
  };
  const query = gql`
    query GetAdResourcesPreSignedUrlQuery(
      $input: [GetAdResourcesPreSignedUrlInput!]!
      $companyID: String
    ) {
      getAdResourcesPreSignedUrls(inputs: $input, companyID: $companyID) {
        readUrl
        writeUrl
      }
    }
  `;
  const variables = {
    input: files.map((f) => ({
      contentType: f.type,
      filenameWithExt: f.name,
    })),
    companyID,
  };
  const result = await client.query<GetAdResourcesPreSignedUrlQuery>({
    query,
    variables,
  });
  return result.data?.getAdResourcesPreSignedUrls ?? null;
}
