import { call, put, takeLeading, all, select } from 'redux-saga/effects';
import * as actions from './billing.actions';
import { selectors as userProfileSelectors } from '../userProfile/userProfile';
import { getCurrentUser } from '../../firebaseHelpers/firebaseHelpers';
import { billingApi } from '../../api/api';
import { ApiCallStatus, LocalStorageKeys } from '../shared/shared.constants';
import { BillingApiRequestTypes, createUpdate } from './billing.constants';
import { ActionType } from 'deox';
import { Types } from '../../api';
import { LocalStorage } from '../../utils/LocalStorage';

function* createBilling(action: ActionType<typeof actions.createBilling>) {
  try {
    yield put(
      actions.setApiStatus(
        createUpdate(BillingApiRequestTypes.CreateBillingAccount, {
          status: ApiCallStatus.Pending,
        })
      )
    );
    const currentUser = getCurrentUser();
    if (currentUser) {
      let billingAccount: Types.BillingAccount | null = LocalStorage.getItem(
        LocalStorageKeys.INITIAL_USER_BILLING_ACCOUNT
      );

      const userProfile = (yield select(
        userProfileSelectors.getUserProfile
      )) as Types.UserProfile;

      if (!billingAccount || billingAccount.userId !== currentUser.uid) {
        const result: Types.BillingAccountResult = yield call(
          billingApi.getBillingAccount,
          userProfile.selectedCompany!.companyID
        );
        if (result.__typename !== 'BillingAccountNotFound') {
          billingAccount = result;
        } else {
          billingAccount = null;
        }
        if (!billingAccount) {
          billingAccount = yield call(
            billingApi.createBillingAccount,
            userProfile.selectedCompany!.companyID
          );
          LocalStorage.setItem(
            LocalStorageKeys.INITIAL_USER_BILLING_ACCOUNT,
            billingAccount
          );
        }
      }
      if (billingAccount && action.payload) {
        const startingCardId = yield call(action.payload);
        const currentCards = billingAccount.cards.map((c) => c.paymentId) ?? [];
        if (startingCardId && !currentCards.includes(startingCardId)) {
          billingAccount = yield call(
            billingApi.addCard,
            startingCardId,
            userProfile.selectedCompany!.companyID
          );
        }
      }
      yield put(
        actions.setApiStatus(
          createUpdate(BillingApiRequestTypes.CreateBillingAccount, {
            status: ApiCallStatus.Success,
            billingAccount: billingAccount!!,
          })
        )
      );
    } else {
      yield put(
        actions.setApiStatus(
          createUpdate(BillingApiRequestTypes.CreateBillingAccount, {
            status: ApiCallStatus.Error,
            error: new Error('Current user undefined'),
          })
        )
      );
    }
  } catch (e) {
    yield put(
      actions.setApiStatus(
        createUpdate(BillingApiRequestTypes.CreateBillingAccount, {
          status: ApiCallStatus.Error,
          error: e,
        })
      )
    );
  }
}

function* fetchBilling() {
  try {
    const userProfile = (yield select(
      userProfileSelectors.getUserProfile
    )) as Types.UserProfile;
    const billingAccount: Types.BillingAccountResult = yield call(
      billingApi.getBillingAccount,
      userProfile.selectedCompany!.companyID
    );
    yield put(actions.fetchBillingSuccess(billingAccount));
  } catch (e) {
    yield put(actions.fetchBillingFailure(e));
  }
}

function* setPrimaryCardId(
  action: ActionType<typeof actions.setPrimaryCardId>
) {
  try {
    yield put(
      actions.setApiStatus(
        createUpdate(BillingApiRequestTypes.SetPrimaryCardId, {
          status: ApiCallStatus.Pending,
        })
      )
    );

    const userProfile = (yield select(
      userProfileSelectors.getUserProfile
    )) as Types.UserProfile;

    const billing: Types.BillingAccount = yield call(
      billingApi.setPrimaryCardId,
      action.payload.cardId,
      userProfile.selectedCompany!.companyID
    );
    yield put(
      actions.updateBilling({
        primaryCardId: billing.primaryCardId,
      })
    );
    yield put(
      actions.setApiStatus(
        createUpdate(BillingApiRequestTypes.SetPrimaryCardId, {
          status: ApiCallStatus.Success,
        })
      )
    );
  } catch (e) {
    yield put(
      actions.setApiStatus(
        createUpdate(BillingApiRequestTypes.SetPrimaryCardId, {
          status: ApiCallStatus.Error,
          error: e,
        })
      )
    );
  }
}

function* addCard(action: ActionType<typeof actions.addCard>) {
  try {
    yield put(
      actions.setApiStatus(
        createUpdate(BillingApiRequestTypes.AddCard, {
          status: ApiCallStatus.Pending,
        })
      )
    );

    const userProfile = (yield select(
      userProfileSelectors.getUserProfile
    )) as Types.UserProfile;

    const billing: Types.BillingAccount = yield call(
      billingApi.addCard,
      action.payload.cardId,
      userProfile.selectedCompany!.companyID
    );
    yield put(
      actions.updateBilling({
        cards: billing.cards,
        primaryCardId: billing.primaryCardId,
      })
    );
    yield put(
      actions.setApiStatus(
        createUpdate(BillingApiRequestTypes.AddCard, {
          status: ApiCallStatus.Success,
        })
      )
    );
  } catch (e) {
    yield put(
      actions.setApiStatus(
        createUpdate(BillingApiRequestTypes.AddCard, {
          status: ApiCallStatus.Error,
          error: e,
        })
      )
    );
  }
}

function* removeCard(action: ActionType<typeof actions.removeCard>) {
  try {
    yield put(
      actions.setApiStatus(
        createUpdate(BillingApiRequestTypes.RemoveCard, {
          status: ApiCallStatus.Pending,
        })
      )
    );

    const userProfile = (yield select(
      userProfileSelectors.getUserProfile
    )) as Types.UserProfile;

    const billing: Types.BillingAccount = yield call(
      billingApi.removeCard,
      action.payload.cardId,
      userProfile.selectedCompany!.companyID
    );
    yield put(
      actions.updateBilling({
        cards: billing.cards,
        primaryCardId: billing.primaryCardId,
      })
    );
    yield put(
      actions.setApiStatus(
        createUpdate(BillingApiRequestTypes.RemoveCard, {
          status: ApiCallStatus.Success,
        })
      )
    );
  } catch (e) {
    yield put(
      actions.setApiStatus(
        createUpdate(BillingApiRequestTypes.RemoveCard, {
          status: ApiCallStatus.Error,
          error: e,
        })
      )
    );
  }
}

function* editCard(action: ActionType<typeof actions.editCard>) {
  try {
    yield put(
      actions.setApiStatus(
        createUpdate(BillingApiRequestTypes.EditCard, {
          status: ApiCallStatus.Pending,
        })
      )
    );

    const userProfile = (yield select(
      userProfileSelectors.getUserProfile
    )) as Types.UserProfile;

    const billing: Types.BillingAccount = yield call(billingApi.editCard, {
      ...action.payload,
      companyID: userProfile.selectedCompany!.companyID,
    });
    yield put(
      actions.updateBilling({
        cards: billing.cards,
      })
    );
    yield put(
      actions.setApiStatus(
        createUpdate(BillingApiRequestTypes.EditCard, {
          status: ApiCallStatus.Success,
        })
      )
    );
  } catch (e) {
    yield put(
      actions.setApiStatus(
        createUpdate(BillingApiRequestTypes.EditCard, {
          status: ApiCallStatus.Error,
          error: e,
        })
      )
    );
  }
}

function* replaceCard(action: ActionType<typeof actions.replaceCard>) {
  try {
    yield put(
      actions.setApiStatus(
        createUpdate(BillingApiRequestTypes.ReplaceCard, {
          status: ApiCallStatus.Pending,
        })
      )
    );

    const userProfile = (yield select(
      userProfileSelectors.getUserProfile
    )) as Types.UserProfile;

    const billing: Types.BillingAccount = yield call(billingApi.replaceCard, {
      ...action.payload,
      companyID: userProfile.selectedCompany!.companyID,
    });
    yield put(
      actions.updateBilling({
        primaryCardId: billing.primaryCardId,
        cards: billing.cards,
        accountStatus: billing.accountStatus,
      })
    );
    yield put(
      actions.setApiStatus(
        createUpdate(BillingApiRequestTypes.ReplaceCard, {
          status: ApiCallStatus.Success,
        })
      )
    );
  } catch (e) {
    yield put(
      actions.setApiStatus(
        createUpdate(BillingApiRequestTypes.ReplaceCard, {
          status: ApiCallStatus.Error,
          error: e,
        })
      )
    );
  }
}

export default function* saga() {
  yield all([
    takeLeading(actions.createBilling.type, createBilling),
    takeLeading(actions.fetchBilling.type, fetchBilling),
    takeLeading(actions.addCard.type, addCard),
    takeLeading(actions.removeCard.type, removeCard),
    takeLeading(actions.editCard.type, editCard),
    takeLeading(actions.replaceCard.type, replaceCard),
    takeLeading(actions.setPrimaryCardId.type, setPrimaryCardId),
  ]);
}
