import React from 'react';
import ReactDOM from 'react-dom';
import './firebaseHelpers/init';
import './index.css';
import App from './components';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { store, history } from './store';
import MomentUtils from '@date-io/moment';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ApolloProvider } from '@apollo/client';
import graphqlClient from './api';

// const PRIMARY_TYPOGRAPHY_STYLE_OPTION: TypographyStyleOptions = {
//   fontFamily: 'Quicksand, sans-serif',
// };
// const SECONDARY_TYPOGRAPHY_STYLE_OPTION: TypographyStyleOptions = {
//   fontFamily: 'Karma, serif',
// };
// const TRINARY_TYPOGRAPHY_STYLE_OPTION: TypographyStyleOptions = {
//   fontFamily: 'Nunito Sans, sans-serif',
// };

// #204261 blue color
// #7AC043 green color
// #FFF white color
// #F5F5F5 off white color
// #B5B5B5 grayish color
// #EE4B4C redish color

const theme = createTheme({
  palette: {
    background: {
      default: '#FFF',
    },
    primary: {
      main: '#204261',
      contrastText: '#FFF',
      light: '#4e6d8f',
      dark: '#001c37',
    },
    secondary: {
      main: '#7AC043',
      light: '#adf373',
      dark: '#488f0b',
      contrastText: '#000',
    },
  },
  typography: {
    fontFamily: 'Quicksand, sans-serif',
  },
});

// logger.addErrorListener((error) => {
//   // some condition maybe where you then handle. you can return true if you want logger to handle it instead
//   return false;
// });

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <ApolloProvider client={graphqlClient}>
                <CssBaseline />
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <App />
                </MuiPickersUtilsProvider>
              </ApolloProvider>
            </ConnectedRouter>
          </Provider>
        </StyledThemeProvider>
      </ThemeProvider>
    </React.Fragment>
  </React.StrictMode>,
  document.getElementById('root')
);
