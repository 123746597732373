import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { getCurrentUser } from '../../../firebaseHelpers/firebaseHelpers';
import Routes from '../../../routes';
import { useSelector } from 'react-redux';
import * as ducks from '../../../ducks/ducks';

export interface PrivateRouteProps
  extends Exclude<RouteProps, 'render' | 'component'> {
  component: React.ComponentType;
}

export default function PrivateRoute({
  component: Component,
  ...rest
}: PrivateRouteProps) {
  const currentUser = getCurrentUser();
  const userProfile = useSelector(ducks.userProfile.selectors.getUserProfile);

  return (
    <Route
      {...rest}
      render={(props) =>
        currentUser &&
        currentUser.emailVerified &&
        userProfile.userId !== 'NULL_USER_ID' &&
        !!userProfile.selectedCompany ? (
          <Component />
        ) : !currentUser ? (
          <Redirect
            to={{
              pathname: Routes.LogIn,
              state: { from: props.location },
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: Routes.SignUpFlow,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
