import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Catalog } from '../../types';

const CREATE_CATALOG_MUTATION = gql`
  mutation CreateCatalog($catalogName: String!, $companyID: String) {
    createCatalog(catalog: $catalogName, companyID: $companyID) {
      catalog {
        catalogId
        displayName
        canDelete
      }
    }
  }
`;

type CreateCatalogResponse = {
  createCatalog: {
    catalog: Catalog;
  };
};

type CreateCatalogOperationVariables = {
  catalogName: string;
  companyID: string;
};

export function useCreateCatalog() {
  const [execute, result] = useMutation<
    CreateCatalogResponse,
    CreateCatalogOperationVariables
  >(CREATE_CATALOG_MUTATION);

  const createVariables = useCallback<
    (catalogName: string, companyID: string) => any
  >(
    (catalogName: string, companyID: string) => ({
      variables: { catalogName, companyID },
    }),
    []
  );

  return {
    execute,
    createVariables,
    ...result,
  };
}
