import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import Routes from '../../../routes';
import { getCurrentUser } from '../../../firebaseHelpers/firebaseHelpers';

export interface AuthForwardingRouteProps
  extends Exclude<RouteProps, 'render' | 'component'> {
  component: React.ComponentType;
}

export default function AuthForwardingRoute({
  component: Component,
  ...rest
}: AuthForwardingRouteProps) {
  const currentUser = getCurrentUser();

  return (
    <Route
      {...rest}
      render={(props) =>
        !currentUser || !currentUser.emailVerified ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: Routes.AdCampaign,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
