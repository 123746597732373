import moment from 'moment';
import { ApiCallStatus } from '../ducks/shared/shared.constants';
import { isBlankOrUndefined } from './StringHelpers';

export type FileInfo = {
  type: string;
  name: string;
};

export function getTimeStampedFileInfoFromDataUrl(
  dataUrl: string,
  fileNamePrefix: string = ''
): FileInfo | undefined {
  if (isBlankOrUndefined(dataUrl)) {
    return undefined;
  }
  const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
  const fileName = `${!!fileNamePrefix ? `${fileNamePrefix}_` : ''}${moment()
    .utc()
    .unix()}.${mimeString.split('/')[1]}`;
  return {
    type: mimeString,
    name: fileName,
  };
}

export async function uploadFile(
  url: string,
  data: string | Blob | File,
  fileInfo: FileInfo
) {
  const { type, name } = fileInfo;
  return await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url, true);
    xhr.onload = function () {
      if (this.status === 200) {
        resolve(ApiCallStatus.Success);
      } else {
        reject(ApiCallStatus.Error);
      }
    };
    xhr.onabort = function () {
      reject(ApiCallStatus.Error);
    };
    xhr.onerror = function () {
      reject(ApiCallStatus.Error);
    };
    xhr.setRequestHeader('Content-Type', type);
    const formData = new FormData();
    formData.append('file-upload', data, name);
    xhr.send(formData);
  });
}

function dataURItoBlob(dataURI: string): Blob {
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString: string;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }

  var ab = new ArrayBuffer(byteString.length);
  // write the bytes of the string to a typed array
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
}

export async function uploadDataUri(
  url: string,
  dataUri: string
): Promise<ApiCallStatus> {
  return await new Promise((resolve, reject) => {
    const blob = dataURItoBlob(dataUri);
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url, true);
    xhr.onload = function () {
      if (this.status === 200) {
        resolve(ApiCallStatus.Success);
      } else {
        reject(ApiCallStatus.Error);
      }
    };
    xhr.onabort = function () {
      reject(ApiCallStatus.Error);
    };
    xhr.onerror = function () {
      reject(ApiCallStatus.Error);
    };
    xhr.send(blob);
  });
}

export async function fetchBlobAsDataUrl(blobUrl: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const xhp = new XMLHttpRequest();
    xhp.open('GET', blobUrl, true);
    xhp.responseType = 'blob';
    xhp.onload = function () {
      const reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result as string);
      };
      reader.onerror = function () {
        reject(this.error);
      };
      reader.readAsDataURL(this.response);
    };
    xhp.send(null);
  });
}
