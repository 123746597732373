import styled from 'styled-components';
import { Theme } from '@material-ui/core/styles';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiPopover from '@material-ui/core/Popover';
import MuiButton from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider/Divider';
import SMProgressButton from '../common/ProgressButton/ProgressButton';
import Container from '@material-ui/core/Container/Container';

type ActionIconProps = {
  buttonColor: string;
  theme: Theme;
};

export const ActionIcon = styled(MuiIconButton).withConfig({
  shouldForwardProp: (prop) => !['buttonColor'].includes(prop),
})<ActionIconProps>`
  color: ${(props) => props.buttonColor};
  padding: ${({ theme }) => theme.spacing(1, 0)};
  margin: ${({ theme }) => theme.spacing(0, 1)};
`;

export const Popover = styled(MuiPopover)`
  // margin-top ${({ theme }) => theme.spacing(0.5)}px;
  .MuiPopover-paper {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }
`;

export const ButtonContainer = styled(Container)`
  position: relative;
  align-items: stretch;
  width: max-content;
  min-width: ${({ theme }) => theme.spacing(24)}px;
  padding: ${({ theme }) => theme.spacing(1, 0)};
  & > * {
    font-size: 0.75rem;
  }
  & > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
`;

export const ProgressButton = styled(SMProgressButton)`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1, 2)};
  justify-content: flex-start;
  text-transform: none;
`;

export const Button = styled(MuiButton)`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1, 2)};
  justify-content: flex-start;
  text-transform: none;
`;

export const ButtonDivider = styled(Divider)`
  margin: ${({ theme }) => theme.spacing(0, 1)};
`;
