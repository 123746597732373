import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Routes, { routePathKeys } from '../routes';
import produce from 'immer';
import _debounce from 'lodash/debounce';
import * as ducks from '../ducks';
import * as moment from 'moment';
import { ApiCallStatus } from '../ducks/shared/shared.constants';
import { noop } from '../components/common/utils';

// Hook
type UseAsyncState<T, E = unknown> = {
  status: ApiCallStatus;
  value: T | null;
  error: E | null;
};

interface UseAsyncResult<T> extends UseAsyncState<T> {
  execute: () => void;
}

export function useAsync<T>(
  func: () => Promise<T> | Promise<T>,
  immediate: boolean = false
): [UseAsyncResult<T>] {
  const [{ value: state, updateMultiple: stateUpdateMultiple }] = useObject<
    UseAsyncState<T>
  >({
    error: null,
    status: ApiCallStatus.Idle,
    value: null,
  });
  const asyncFunction = React.useRef(func);
  const useImmediately = React.useRef(immediate);

  const execute = React.useCallback(async () => {
    stateUpdateMultiple({
      error: null,
      status: ApiCallStatus.Pending,
      value: null,
    });

    try {
      const response = isPromiseResult(asyncFunction.current)
        ? await asyncFunction.current()
        : await asyncFunction.current();
      stateUpdateMultiple({
        value: response,
        status: ApiCallStatus.Success,
      });
    } catch (error) {
      stateUpdateMultiple({
        error: error,
        status: ApiCallStatus.Error,
      });
    }
  }, [stateUpdateMultiple]);

  React.useEffect(() => {
    asyncFunction.current = func;
  }, [func]);

  React.useEffect(() => {
    if (useImmediately.current) {
      execute();
    }
  }, [execute]);

  return [{ execute, ...state }];
}

interface UseAsyncFuncResult<T> extends UseAsyncState<T> {
  execute: (arg: Promise<T> | (() => Promise<T>)) => Promise<void>;
}

function isPromiseResult<T>(
  arg: Promise<T> | (() => Promise<T>)
): arg is Promise<T> {
  return (arg as () => Promise<T>).bind === undefined;
}

export function useAsyncFunc<T>(): [UseAsyncFuncResult<T>] {
  const [{ value: state, updateMultiple: stateUpdateMultiple }] = useObject<
    UseAsyncState<T>
  >({
    error: null,
    status: ApiCallStatus.Idle,
    value: null,
  });

  const execute = React.useCallback(
    async (prom: Promise<T> | (() => Promise<T>)) => {
      stateUpdateMultiple({
        error: null,
        status: ApiCallStatus.Pending,
        value: null,
      });

      try {
        const response = isPromiseResult(prom) ? await prom : await prom();
        stateUpdateMultiple({
          value: response,
          status: ApiCallStatus.Success,
        });
      } catch (error) {
        stateUpdateMultiple({
          error: error,
          status: ApiCallStatus.Error,
        });
      }
    },
    [stateUpdateMultiple]
  );

  return [{ execute, ...state }];
}

export function useRouting(): [
  (route: Routes, state?: {}) => void,
  (route: Routes, state?: {}) => () => void
] {
  const dispatch = useDispatch();
  const history = useHistory();

  const updatePageVisited = useCallback(
    (pageKey: string) => {
      dispatch(
        ducks.userTracing.actions.updatePageVisited({
          pageKey,
          visitedAt: moment.utc(),
        })
      );
    },
    [dispatch]
  );

  const goTo = useCallback(
    (route: Routes, state: {} = {}) => {
      history.push(route, state);
      updatePageVisited(routePathKeys[route]);
    },
    [history, updatePageVisited]
  );

  const goToThunk = useCallback(
    (route: Routes, state: {} = {}) => {
      return () => {
        history.push(route, state);
        updatePageVisited(routePathKeys[route]);
      };
    },
    [history, updatePageVisited]
  );
  return [goTo, goToThunk];
}

export function useString(initial: string) {
  const [value, setValue] = React.useState<string>(initial);
  const onChange = React.useCallback(
    (
      e: React.ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      setValue(e.target.value);
    },
    [setValue]
  );
  return React.useMemo(
    () => [
      {
        value,
        setValue,
        onChange,
      },
    ],
    [value, setValue, onChange]
  );
}

export function useArray<T>(initial: T[] = []) {
  const [value, setValue] = React.useState(initial);

  const add = React.useCallback(
    (a: T) => setValue((r: T[]) => [...r, a]),
    [setValue]
  );
  const clear = React.useCallback(() => setValue([]), [setValue]);
  const updateIndex = React.useCallback(
    (idx: number, value: T) =>
      setValue(
        produce((draftState) => {
          draftState[idx] = value;
        })
      ),
    [setValue]
  );
  const removeIndex = React.useCallback(
    (index: number) =>
      setValue((arr: T[]) => arr.filter((_, i) => i !== index)),
    [setValue]
  );

  return React.useMemo(
    () => [
      {
        value,
        setValue,
        add,
        clear,
        updateIndex,
        removeIndex,
      },
    ],
    [value, setValue, add, clear, updateIndex, removeIndex]
  );
}

export type UseObjectResult<T> = {
  value: T;
  setValue: React.Dispatch<React.SetStateAction<T>>;
  updateProp: <K extends keyof T>(key: K, value: T[K]) => void;
  updatePropThunk: <K extends keyof T>(key: K) => (value: T[K]) => void;
  immerProduce: (draftUpdater: (draft: T) => void) => void;
  updateMultiple: (updates: Partial<T>) => void;
};

export function useObject<T extends object>(initial: T): [UseObjectResult<T>] {
  const [value, setValue] = React.useState<T>(initial);

  const updateProp = React.useCallback(
    <K extends keyof T>(key: K, value: T[K]) =>
      setValue((prevState) =>
        produce(prevState, (draft: T) => {
          draft[key] = value;
        })
      ),
    [setValue]
  );
  const updatePropThunk = React.useCallback(
    <K extends keyof T>(key: K) =>
      (value: T[K]) =>
        updateProp(key, value),
    [updateProp]
  );
  const immerProduce = React.useCallback(
    (draftUpdater: (draft: T) => void) =>
      setValue((prevState) =>
        produce(prevState, (draft: T) => draftUpdater(draft))
      ),
    [setValue]
  );
  const updateMultiple = React.useCallback(
    (updates: Partial<T>) =>
      setValue((prevState) =>
        produce(prevState, (draft: T) => {
          Object.keys(updates).forEach((key) => {
            const typedKey = key as keyof T;
            if (updates[typedKey] !== undefined) {
              draft[typedKey] = updates[typedKey] as T[keyof T];
            }
          });
        })
      ),
    [setValue]
  );

  return React.useMemo(
    () => [
      {
        value,
        setValue,
        updateProp,
        updatePropThunk,
        immerProduce,
        updateMultiple,
      },
    ],
    [value, setValue, updateProp, updatePropThunk, immerProduce, updateMultiple]
  );
}

export function useCompare<T>(val: T): boolean {
  const [prevVal] = usePrevious(val);
  return prevVal !== val;
}

export function usePrevious<T>(
  value: T,
  initialValue: T = undefined
): [T | undefined] {
  const ref = React.useRef<T>(initialValue);
  React.useEffect(() => {
    ref.current = value;
  }, [value]);
  return [ref.current];
}

type UseEffectAfterCountCallback = () => void;

export function useEffectAfterCount(
  callback: UseEffectAfterCountCallback,
  list: React.DependencyList = [],
  startAfter: number = 1
) {
  const updateCount = React.useRef(startAfter);
  const callbackRef = React.useRef(callback);
  callbackRef.current = callback;
  React.useEffect(() => {
    if (updateCount.current > 0) {
      updateCount.current = updateCount.current - 1;
      return;
    }
    callbackRef.current();
  }, [list]);
}

type UseFunctionRefCallBack = (...args: any[]) => any;
export function useFunctionRef(initialFunction: UseFunctionRefCallBack = noop) {
  const functionRef = React.useRef<UseFunctionRefCallBack>(initialFunction);
  const setFunctionRef = (fn: UseFunctionRefCallBack) => {
    functionRef.current = fn;
  };
  return [{ functionRef, setFunctionRef }];
}

export function useOnScreen(
  ref: React.MutableRefObject<Element | undefined | null>,
  rootMargin = '0px',
  updateDelay = 0
) {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = React.useState(false);

  const debouncedUpdate = React.useMemo(
    () => _debounce(setIntersecting, updateDelay),
    [setIntersecting, updateDelay]
  );

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        debouncedUpdate(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(ref.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]); // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting;
}

export const useForceUpdate = () => {
  const [, setState] = React.useState(true);
  const forceUpdate = React.useCallback(() => {
    setState((s) => !s);
  }, []);

  return forceUpdate;
};

// must be used on page where selected company is know to be available. We can update to handler better later
export function useGetSelectedCompanyID(): string {
  const userProfile = useSelector(ducks.userProfile.selectors.getUserProfile);
  return useMemo(
    () => userProfile.selectedCompany?.companyID!,
    [userProfile.selectedCompany]
  );
}
