import { combineReducers } from 'redux';
import * as actions from './userTracing.actions';
import { createReducer } from 'deox';
import produce from 'immer';
import { Types } from '../../api';
import {
  UserTraceApiRequests,
  UserTraceApiRequestTypes,
} from './userTracing.constants';
import { ApiCallStatus } from '../shared/shared.constants';
import * as authActions from '../auth/auth.actions';
import { AuthApiRequestTypes } from '../auth/auth.constants';

const NULL_USER_TRACING: Types.UserTrace = {
  __typename: 'UserTrace',
  userId: '',
  pagesVisitedStatistics: {},
};

const userTracing = createReducer(NULL_USER_TRACING, (handleAction) => [
  handleAction(
    [actions.fetchUserTraceSuccess, actions.createUserTraceSuccess],
    (_, { payload }) =>
      payload.__typename === 'UserTraceNotFound' ? NULL_USER_TRACING : payload
  ),
  handleAction(
    [actions.fetchUserTraceFailure, actions.createUserTraceFailure],
    () => NULL_USER_TRACING
  ),
  handleAction(authActions.setApiStatus, (prevState, { payload }) =>
    produce(prevState, () => {
      if (
        payload.requestType === AuthApiRequestTypes.DeleteAccount ||
        payload.requestType === AuthApiRequestTypes.LogOut
      ) {
        if (payload.update.status === ApiCallStatus.Success) {
          return NULL_USER_TRACING;
        }
      }
    })
  ),
  handleAction(
    actions.updateUserTrace,
    (prevState, { payload: { pagesVisitedStatistics } }) =>
      produce(prevState, (draft) => {
        if (pagesVisitedStatistics) {
          draft.pagesVisitedStatistics = pagesVisitedStatistics;
        }
      })
  ),
  handleAction(actions.setApiStatus, (prevState, { payload }) =>
    produce(prevState, () => {
      if (payload.requestType === UserTraceApiRequestTypes.CreateUserTrace) {
        if (payload.update.status === ApiCallStatus.Success) {
          return (
            payload.update as UserTraceApiRequests[UserTraceApiRequestTypes.CreateUserTrace]
          ).userTrace!!;
        } else if (payload.update.status === ApiCallStatus.Error) {
          return NULL_USER_TRACING;
        }
      }
    })
  ),
]);

const DEFAULT_API_REQUESTS: UserTraceApiRequests = {
  [UserTraceApiRequestTypes.CreateUserTrace]: {
    status: ApiCallStatus.Idle,
  },
  [UserTraceApiRequestTypes.UpdatePageVisited]: {
    status: ApiCallStatus.Idle,
  },
  [UserTraceApiRequestTypes.UpdateHTTPInfo]: {
    status: ApiCallStatus.Idle,
  },
};

const apiRequests = createReducer(DEFAULT_API_REQUESTS, (handleAction) => [
  handleAction(actions.setApiStatus, (prevState, { payload }) =>
    produce(prevState, (draftState: UserTraceApiRequests) => {
      draftState[payload.requestType] = payload.update;
    })
  ),
]);

export default combineReducers({
  userTracing,
  apiRequests,
});
