import {
  NAME,
  UserProfileApiRequests,
  UserProfileStatusUpdates,
} from './userProfile.constants';
import { ActionType, createAction } from 'deox';
import { Types, userProfileApi } from '../../api';
import { ApolloError } from '@apollo/client';
import { DropZoneImageDetails } from '../../components/common/SingleImageDropZone';

export const fetchUserProfile = createAction(`${NAME}/fetchUserProfile`);
export const fetchUserProfileSuccess = createAction(
  `${NAME}/fetchUserProfileSuccess`,
  (resolve) => (payload: Types.UserProfileResult) => resolve(payload)
);
export const fetchUserProfileFailure = createAction(
  `${NAME}/fetchUserProfileFailure`,
  (resolve) => (payload: ApolloError) => resolve(payload)
);
export function isFetchUserProfileFailure(
  arg: ActionType<
    typeof fetchUserProfileFailure | typeof fetchUserProfileSuccess
  >
): arg is ActionType<typeof fetchUserProfileFailure> {
  return arg.type === fetchUserProfileFailure.type;
}

type CreateUserProfileWithTeamRequest = {
  accessCode: string;
};
type CreateUserProfileWithCompanyRequest = Pick<Types.Company, 'businessInfo'>;

export type CreateUserProfilePayload =
  | CreateUserProfileWithTeamRequest
  | CreateUserProfileWithCompanyRequest;

export function isCreateUserProfileWithTeamRequest(
  arg: CreateUserProfilePayload
): arg is CreateUserProfileWithTeamRequest {
  return (arg as CreateUserProfileWithTeamRequest).accessCode !== undefined;
}

export const createUserProfile = createAction(
  `${NAME}/createUserProfile`,
  (resolve) => (payload: CreateUserProfilePayload) => resolve(payload)
);
export const createUserProfileSuccess = createAction(
  `${NAME}/createUserProfileSuccess`,
  (resolve) => (payload: Types.UserProfile) => resolve(payload)
);
export const createUserProfileFailure = createAction(
  `${NAME}/createUserProfileFailure`,
  (resolve) => (payload: any) => resolve(payload)
);

export const updateCompany = createAction(
  `${NAME}/updateCompany`,
  (resolve) =>
    (
      payload: Partial<
        Pick<
          Types.Company,
          'businessInfo' | 'verification' | 'logo' | 'teamMembers'
        >
      >
    ) =>
      resolve(payload)
);

export const setSelectedCompany = createAction(
  `${NAME}/setSelectedCompany`,
  (resolve) => (payload: Types.SelectedCompany) => resolve(payload)
);

export const unsetSelectedCompany = createAction(
  `${NAME}/unsetSelectedCompany`,
  (resolve) => () => resolve()
);

export const updateUserProfile = createAction(
  `${NAME}/updateUserProfile`,
  (resolve) => (payload: Partial<Pick<Types.UserProfile, 'onboarded'>>) =>
    resolve(payload)
);

export const updateBusinessInfo = createAction(
  `${NAME}/updateBusinessInfo`,
  (resolve) => (payload: userProfileApi.UpdateBusinessInfoInput) =>
    resolve(payload)
);

export const updateUserOnboarded = createAction(
  `${NAME}/updateUserOnboarded`,
  (resolve) => (payload: boolean) => resolve(payload)
);

export const uploadVerificationDocument = createAction(
  `${NAME}/uploadVerificationDocument`,
  (resolve) => (payload: File) => resolve(payload)
);

export const uploadBusinessUserProfileImage = createAction(
  `${NAME}/uploadBusinessUserProfileImage`,
  (resolve) => (payload: DropZoneImageDetails) => resolve(payload)
);

export const setApiStatus = createAction(
  `${NAME}/setApiStatus`,
  (resolve) =>
    <T extends keyof UserProfileApiRequests>(
      payload: UserProfileStatusUpdates<T>
    ) =>
      resolve(payload)
);
