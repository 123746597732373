export const number2Currency = (
  value: number,
  currency: string = 'USD',
  locales: string | string[] = 'en-US'
) => {
  const nf = new Intl.NumberFormat(locales, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return nf.format(value);
};

/**
 *
 * @param mm- month value
 * @return - string representation of month with only two digits.
 *
 * e.g. Month 2 returns -> '02'
 */
export const stringifyMonth = (mm: number) => ('00' + mm).slice(-2);

export const format2DigitMonth = (mm?: string | null) => {
  if (!mm) {
    return null;
  }
  let formatted = mm;
  while (formatted.length < 2) formatted = '0' + formatted;
  return formatted;
};

/**
 *
 * @param YYYY - year value
 * @param trim - boolean indicating (true: default) year should be 2 digits and (false) year should be 4 digits
 * @return - string representation of year with only two/four digits.
 *
 *      Year  2020 returns -> '20' if trim is true
 * 			Year  2020 returns -> '2020' if trim is false
 */
export const stringifyYear = (YYYY: number, trim: boolean = true) =>
  ('0000' + YYYY).slice(trim ? -2 : -4);

export const format4DigitYear = (YYYY: string) => {
  if (!YYYY) {
    return null;
  }
  let formatted = YYYY;
  if (formatted.length === 2) formatted = '20' + formatted;
  return formatted;
};

export const isBlankOrUndefined = (str: string | undefined): boolean => {
  return !str || /^\s*$/.test(str);
};

/**
 * function pluralizes word (improper noun) based on a count
 * @param word - string to pluralize
 * @param count - value to determine if word should be singular or plural
 */
export function stringPlurality(word: string, count: number) {
  if (!word || count < 0 || count === 1) return word;
  const len = word.length;
  const endsInY = word[len - 1].toLowerCase() === 'y';
  const checker = len > 1 ? word[len - 2] : '';
  const needsIesSuffix =
    endsInY && !['a', 'e', 'i', 'o', 'u', 'y'].includes(checker.toLowerCase());
  const wordBase = needsIesSuffix ? `${word.slice(0, -1)}ie` : word;
  return `${wordBase}s`;
}

export function urlPathAppend(origin: string, path: string): string {
  return origin.replace(/\/?$/, '/') + path.replace(/^\//g, '');
}

export function pluralStringJoin(strings: string[]): string {
  if (strings.length < 3) {
    return strings.join(', ');
  }
  let strs = strings.slice();
  const lastString = strs.pop();
  return `${strs.join(', ')} and ${lastString}`;
}

export function truncateStringByWordCount(
  str: string,
  noOfWords: number
): string {
  if (noOfWords === undefined) return str;
  const x = new RegExp('([^\\s]+\\s+){' + noOfWords + '}', 'g');
  const matches = str.match(x);
  return matches ? matches[0].trim() : str;
}

export function truncateStringByCharCount(
  str: string,
  noOfChars: number
): string {
  if (noOfChars === undefined) return str;
  return str.substr(0, noOfChars);
}

export function wordCount(str: string): number {
  const trimmedString = str.trim();
  if (!trimmedString) return 0;
  return trimmedString.split(new RegExp(/\s+/)).length;
}
