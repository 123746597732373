import { useCallback } from 'react';
import { gql, useQuery, useLazyQuery, QueryLazyOptions } from '@apollo/client';
import { ALL_AD_FIELDS } from '../../constants';
import { Catalog } from '../../types';

const GET_CATALOG_ACTIVE_ADS = gql`
    query GetCatalogActiveAds($catalogId: String!, $adFirst: Int, $adEndCursor: String, $companyID: String){
      catalog(catalogId: $catalogId, companyID: $companyID) {
        catalogId
        displayName
        activeAds(first: $adFirst, after: $adEndCursor) {
          edges {
            cursor
            node {
              ${ALL_AD_FIELDS}
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    }
  `;

type GetCatalogActiveAdsResponse = {
  catalog: Catalog;
};
type GetCatalogActiveAdsOperationVariables = {
  adFirst?: number;
  adEndCursor?: string;
  catalogId: string;
  companyID: string;
};
type GetCatalogAdsRequest = GetCatalogActiveAdsOperationVariables;

export function useGetCatalogActiveAds({
  adFirst = 10,
  catalogId,
  adEndCursor,
  companyID,
}: GetCatalogAdsRequest) {
  return useQuery<
    GetCatalogActiveAdsResponse,
    GetCatalogActiveAdsOperationVariables
  >(GET_CATALOG_ACTIVE_ADS, {
    variables: { adFirst, catalogId, adEndCursor, companyID },
  });
}

export function useLazyGetCatalogActiveAds() {
  const [execute, result] = useLazyQuery<
    GetCatalogActiveAdsResponse,
    GetCatalogActiveAdsOperationVariables
  >(GET_CATALOG_ACTIVE_ADS);

  const createVariables = useCallback<
    (
      a: GetCatalogActiveAdsOperationVariables
    ) => QueryLazyOptions<GetCatalogActiveAdsOperationVariables>
  >(
    ({ catalogId, adFirst = 10, adEndCursor, companyID }) => ({
      variables: { adFirst, catalogId, adEndCursor, companyID },
    }),
    []
  );

  return {
    execute,
    createVariables,
    ...result,
  };
}
