import { call, put, takeLeading, all } from 'redux-saga/effects';
import * as actions from './userTracing.actions';
import { getCurrentUser } from '../../firebaseHelpers/firebaseHelpers';
import { userTraceApi } from '../../api';
import { ActionType } from 'deox';
import {
  UserTraceApiRequestTypes,
  createUpdate,
} from './userTracing.constants';
import { ApiCallStatus, LocalStorageKeys } from '../shared/shared.constants';
import * as userProfileDucks from '../userProfile';
import { Types } from '../../api';
import { getCurrentUserAgent } from '../../utils/UserInfo';
import { LocalStorage } from '../../utils/LocalStorage';

function* createUserTrace() {
  try {
    yield put(
      actions.setApiStatus(
        createUpdate(UserTraceApiRequestTypes.CreateUserTrace, {
          status: ApiCallStatus.Pending,
        })
      )
    );
    const currentUser = getCurrentUser();
    if (currentUser) {
      let userTrace: Types.UserTrace | null = LocalStorage.getItem(
        LocalStorageKeys.INITIAL_USER_TRACE
      );

      if (!userTrace || userTrace.userId !== currentUser.uid) {
        const result: Types.UserTraceResult = yield call(
          userTraceApi.getUserTrace
        );
        if (result.__typename !== 'UserTraceNotFound') {
          userTrace = result;
        } else {
          userTrace = null;
        }
        if (!userTrace) {
          userTrace = yield call(userTraceApi.createUserTrace);
          LocalStorage.setItem(LocalStorageKeys.INITIAL_USER_TRACE, userTrace);
        }
      }
      const userAgent = yield call(getCurrentUserAgent);
      yield put(actions.updateHTTPInfo(userAgent));
      yield put(
        actions.setApiStatus(
          createUpdate(UserTraceApiRequestTypes.CreateUserTrace, {
            status: ApiCallStatus.Success,
            userTrace: userTrace!!,
          })
        )
      );
    } else {
      yield put(
        actions.setApiStatus(
          createUpdate(UserTraceApiRequestTypes.CreateUserTrace, {
            status: ApiCallStatus.Error,
            error: new Error('Current user undefined'),
          })
        )
      );
    }
  } catch (e) {
    yield put(
      actions.setApiStatus(
        createUpdate(UserTraceApiRequestTypes.CreateUserTrace, {
          status: ApiCallStatus.Error,
          error: e,
        })
      )
    );
  }
}

function* fetchUserTrace() {
  try {
    const userTrace: Types.UserTraceResult = yield call(
      userTraceApi.getUserTrace
    );
    yield put(actions.fetchUserTraceSuccess(userTrace));
  } catch (e) {
    yield put(actions.fetchUserTraceFailure(e));
  }
}

function* updatePageVisited(
  action: ActionType<typeof actions.updatePageVisited>
) {
  try {
    yield put(
      actions.setApiStatus(
        createUpdate(UserTraceApiRequestTypes.UpdatePageVisited, {
          status: ApiCallStatus.Pending,
        })
      )
    );

    const userTrace: UserTrace = yield call(
      userTraceApi.updatePageStatistics,
      action.payload
    );

    yield put(
      actions.updateUserTrace({
        pagesVisitedStatistics: userTrace.pagesVisitedStatistics,
      })
    );

    yield put(
      actions.setApiStatus(
        createUpdate(UserTraceApiRequestTypes.UpdatePageVisited, {
          status: ApiCallStatus.Success,
        })
      )
    );
  } catch (e) {
    yield put(
      actions.setApiStatus(
        createUpdate(UserTraceApiRequestTypes.UpdatePageVisited, {
          status: ApiCallStatus.Error,
          error: e,
        })
      )
    );
  }
}

export function* updateHTTPInfo(
  action: ActionType<typeof actions.updateHTTPInfo>
) {
  try {
    yield put(
      actions.setApiStatus(
        createUpdate(UserTraceApiRequestTypes.UpdateHTTPInfo, {
          status: ApiCallStatus.Pending,
        })
      )
    );

    yield call(userTraceApi.updateUserHTTPInformation, action.payload);

    yield put(
      actions.setApiStatus(
        createUpdate(UserTraceApiRequestTypes.UpdateHTTPInfo, {
          status: ApiCallStatus.Success,
        })
      )
    );
  } catch (e) {
    yield put(
      actions.setApiStatus(
        createUpdate(UserTraceApiRequestTypes.UpdateHTTPInfo, {
          status: ApiCallStatus.Error,
          error: e,
        })
      )
    );
  }
}

export default function* saga() {
  yield all([
    takeLeading(
      userProfileDucks.actions.createUserProfile.type,
      createUserTrace
    ),
    takeLeading(actions.fetchUserTrace.type, fetchUserTrace),
    takeLeading(actions.updatePageVisited.type, updatePageVisited),
    takeLeading(actions.updateHTTPInfo.type, updateHTTPInfo),
    takeLeading(actions.createUserTrace.type, createUserTrace),
  ]);
}
