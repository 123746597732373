import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Role } from '../../types';

const SEND_TEAM_INVITE_MUTATION = gql`
  mutation SendTeamInvite($input: SendTeamInviteInput!) {
    sendTeamInvite(input: $input) {
      success
      message
      code
    }
  }
`;

export type SendTeamInviteResponse = {
  sendTeamInvite: {
    success: boolean;
  };
};

type SendTeamInviteOperationVariables = {
  input: {
    email: string;
    companyID: string;
    roles: Role[];
  };
};

export function useSendTeamInvite() {
  const [execute, result] = useMutation<
    SendTeamInviteResponse,
    SendTeamInviteOperationVariables
  >(SEND_TEAM_INVITE_MUTATION);

  const createVariables = useCallback<
    (a: SendTeamInviteOperationVariables['input']) => any
  >(
    ({ companyID, roles, email }) => ({
      variables: { input: { companyID, roles, email } },
    }),
    []
  );

  return {
    execute,
    createVariables,
    ...result,
  };
}
