import { Types } from '../../api';
import { ApiCallStatus } from '../shared/shared.constants';

export const NAME = 'userProfile';

export enum UserProfileApiRequestTypes {
  CreateUserProfile = 'createUserProfile',
  UpdateBusinessInfo = 'updateBusinessInfo',
  UpdateUserOnboarded = 'updateUserOnboarded',
  UploadVerificationDocument = 'updateVerificationDocument',
  UploadBusinessUserProfileImage = 'uploadBusinessUserProfileImage',
}

export type UserProfileApiRequests = {
  [UserProfileApiRequestTypes.CreateUserProfile]: {
    userProfile?: Types.UserProfile;
    status: ApiCallStatus;
    error?: any;
  };
  [UserProfileApiRequestTypes.UpdateBusinessInfo]: {
    status: ApiCallStatus;
    error?: any;
  };
  [UserProfileApiRequestTypes.UpdateUserOnboarded]: {
    status: ApiCallStatus;
    error?: any;
  };
  [UserProfileApiRequestTypes.UploadVerificationDocument]: {
    status: ApiCallStatus;
    error?: any;
  };
  [UserProfileApiRequestTypes.UploadBusinessUserProfileImage]: {
    status: ApiCallStatus;
    error?: any;
  };
};

export type UserProfileStatusUpdates<P extends keyof UserProfileApiRequests> = {
  requestType: P;
  update: UserProfileApiRequests[P];
};

export function createUpdate<P extends keyof UserProfileApiRequests>(
  requestType: P,
  update: UserProfileApiRequests[P]
): UserProfileStatusUpdates<P> {
  return {
    requestType,
    update,
  };
}
