export enum AdResourceTypes {
  Picture = 'Picture',
  Carousel = 'Carousel',
  Gif = 'Gif',
  Poll = 'Poll',
}

export const AD_TYPES_CATALOG_ID_TO_AD_TYPES: {
  [key: string]: AdResourceTypes;
} = {
  '79nEIpBI5q62a7HN0pFj': AdResourceTypes.Carousel,
  T5RlTNO1WXigUglyjafY: AdResourceTypes.Picture,
  l45ysi09IC6JuYSZ8IQn: AdResourceTypes.Poll,
  pfaFfC3O0axG8YRvxFre: AdResourceTypes.Gif,
} as const;

export const AD_TYPES_TO_AD_TYPES_CATALOG_ID: Record<
  keyof typeof AdResourceTypes,
  string
> = {
  [AdResourceTypes.Carousel]: '79nEIpBI5q62a7HN0pFj',
  [AdResourceTypes.Picture]: 'T5RlTNO1WXigUglyjafY',
  [AdResourceTypes.Poll]: 'l45ysi09IC6JuYSZ8IQn',
  [AdResourceTypes.Gif]: 'pfaFfC3O0axG8YRvxFre',
} as const;

export enum AdTypes {
  Active,
  Archived,
  Draft,
}

export enum ResourceProcessingStatus {
  Staged = 'Staged',
  Processing = 'Processing',
  Complete = 'Complete',
  CompleteWithSensor = 'CompleteWithSensor',
  Error = 'Error',
}

export type ResourceUrl = {
  readUrl: string;
  writeUrl: string;
};

export type MediaResource = {
  url: ResourceUrl;
  link: string;
};

export type PictureResources = {
  imageUrl: ResourceUrl;
  picture: MediaResource;
};

export type CarouselResources = {
  imageUrls: ResourceUrl[];
  pictures: MediaResource[];
};

export type GifResources = {
  gifUrl: ResourceUrl;
  gif: MediaResource;
};

export type PollResources = {
  pollQuestion: string;
  pollOptions: string[];
};

export type AdResources =
  | PictureResources
  | CarouselResources
  | GifResources
  | PollResources;

export type Ad = {
  adTypesCatalogEntry: {
    adTypesCatalogId: string;
  };
  catalog: {
    catalogId: string;
  };
  adId: string;
  isDraft: boolean;
  runsContinuously: boolean;
  title: string;
  categories: AdCategory[];
  tags: string[];
  link?: string;
  caption: string;
  detailedDescription: string;
  flare: string;
  startsAfterUtcEpoch: number;
  endsAfterUtcEpoch: number;
  resources: AdResources;
  callToActionText?: string;
  processingStatus?: ResourceProcessingStatus;
  processingContentRiskTypes?: string[];
};

export type AdFeedEdge = {
  cursor: string;
  node: Ad;
};

export type PageInfo = {
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  startCursor?: string;
  endCursor?: string;
};

export type AdFeed = {
  edges: AdFeedEdge[];
  pageInfo: PageInfo;
};

export type Catalog = {
  catalogId: string;
  canDelete?: boolean;
  displayName: string;
  activeAds?: AdFeed;
  draftAds?: AdFeed;
  archivedAds?: AdFeed;
  adsWithMetrics?: AdFeed;
};

export type AdCategory = {
  id: string;
  name: string;
};

export type AdCategoryGroupCategory = {
  id: string;
  name: string;
};

export type AdCategoryGroup = {
  name: string;
  categories: AdCategoryGroupCategory[];
};

export type MutationResponse = {
  code: number;
  success: boolean;
  message: string;
};

export type AdTypesCatalogEntry = {
  adTypesCatalogId: string;
  displayName: string;
  iconImageUrl: string;
  amount: number;
  currency: string;
};

export type CatalogAdTypes = keyof Pick<
  Catalog,
  'activeAds' | 'archivedAds' | 'draftAds'
>;

// User profile stuff

export enum VerificationStatus {
  Unverified = 'UNVERIFIED',
  VerificationPending = 'VERIFICATION_PENDING',
  VerificationFailed = 'VERIFICATION_FAILED',
  ReverificationNeeded = 'REVERIFICATION_NEEDED',
  Verified = 'VERIFIED',
}

export type VerificationInfo = {
  status: VerificationStatus;
  filename?: string;
  result?: string;
};

type UserProfileResultNode = {
  __typename: 'UserProfile' | 'UserProfileNotFound';
};

export type UserPermissions = {
  isOwner: boolean;
  canEditPromotions: boolean;
  canStopPromotions: boolean;
  canEditBilling: boolean;
  canDeleteBilling: boolean;
  canEditCatalog: boolean;
  canDeleteCatalog: boolean;
  canEditCompany: boolean;
  canManageTeam: boolean;
};

export type Role =
  | 'owner'
  | 'metrics.view'
  | 'team.admin'
  | 'promotion.admin'
  | 'promotion.edit'
  | 'promotion.stop'
  | 'promotion.view'
  | 'catalog.admin'
  | 'catalog.edit'
  | 'catalog.delete'
  | 'catalog.view'
  | 'billing.admin'
  | 'billing.view'
  | 'billing.card.edit'
  | 'billing.card.delete'
  | 'company.edit'
  | 'company.view';

export const TEAM_ROLE_MAP: Record<Role, string> = {
  owner: 'Owner',
  'team.admin': 'Team Admin',
  'metrics.view': 'Metrics View',
  'promotion.admin': 'Promotion Admin',
  'promotion.edit': 'Promotion Edit',
  'promotion.stop': 'Promotion Stop',
  'promotion.view': 'Promotion View',
  'catalog.admin': 'Catalog Admin',
  'catalog.edit': 'Catalog Edit',
  'catalog.delete': 'Catalog Delete',
  'catalog.view': 'Catalog View',
  'billing.admin': 'Billing Admin',
  'billing.view': 'Billing View',
  'billing.card.edit': 'Billing Card Edit',
  'billing.card.delete': 'Billing Card Delete',
  'company.edit': 'Company Edit',
  'company.view': 'Company View',
};

export type Company = {
  companyID: string;
  businessInfo: BusinessInfo;
  verification: VerificationInfo;
  logo?: BusinessUserProfileImageUrl;
  teamMembers: TeamMemberUserProfile[];
};
export type SelectedCompany = Company & {
  permissions: UserPermissions;
};

export type UserProfile = UserProfileResultNode & {
  __typename: 'UserProfile';
  userId: string;
  email: string;
  onboarded?: boolean;
  selectedCompany?: SelectedCompany;
  selectableCompanies: Company[];
};

export type TeamMemberUserProfile = UserProfileResultNode & {
  __typename: 'UserProfile';
  userId: string;
  email: string;
  companyRoles: Role[];
};

export type UserProfileNotFound = UserProfileResultNode & {
  __typename: 'UserProfileNotFound';
  reason: string;
};

export type UserProfileResult = UserProfile | UserProfileNotFound;

export type BusinessInfo = {
  companyName: string;
  businessAlias?: string;
  yearEstablished: number;
  industry: string;
  homepage?: string;
};

export type BusinessUserProfileImageUrl = {
  readUrl: string;
  writeUrl: string;
};

export type BusinessUserProfileImagePresignedUrls = {
  readUrl: string;
  writeUrl: string;
};

export type BusinessVerificationStatusUpdate = {
  status: VerificationStatus;
};

export type UserProfileMutationResponse = MutationResponse & {
  userProfile?: UserProfile;
};

export type BusinessInfoUpdate = BusinessInfo;

// user trace

type UserTraceResultNode = {
  __typename: 'UserTrace' | 'UserTraceNotFound';
};

export type UserTraceNotFound = UserTraceResultNode & {
  __typename: 'UserTraceNotFound';
  reason: string;
};

export type UserTrace = UserTraceResultNode & {
  __typename: 'UserTrace';
  userId: string;
  pagesVisitedStatistics?: PagesVisitedStatistics;
};

export type UserTraceResult = UserTrace | UserTraceNotFound;

export type PagesVisitedStatistics = {
  [pageKey: string]: PageStatistics;
};

export type PageStatistics = {
  lastVisited: string;
  visitCount: number;
};

export type PageVisited = {
  pageKey: string;
  visitedAt: moment.Moment;
};

// billing

type BillingAccountResultNode = {
  __typename: 'BillingAccount' | 'BillingAccountNotFound';
};

export type BillingAccount = BillingAccountResultNode & {
  __typename: 'BillingAccount';
  userId: string;
  cards: PaymentCard[];
  primaryCardId?: CardId;
  accountStatus: BillingAccountStatus;
};

export type BillingAccountNotFound = BillingAccountResultNode & {
  __typename: 'BillingAccountNotFound';
  reason: string;
};

export type BillingAccountResult = BillingAccount | BillingAccountNotFound;

export type PaymentCard = {
  paymentId: CardId;
  brand: string;
  cardLast4: string;
  expirationMonth: number;
  expirationYear: number;
  expired: boolean;
  error?: string;
};

export enum BillingAccountStatus {
  Ok = 'OK',
  ChargesDeclined = 'CHARGES_DECLINED',
  Unknown = 'UNKNOWN_ACCOUNT',
}

type CardId = string;

export type EditCardMutationInput = {
  cardId: CardId;
  companyID: string;
  expirationMonth?: number;
  expirationYear?: number;
  addressCity?: string;
  addressCountry?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressState?: string;
  addressZip?: string;
};

export type ReplaceCardMutationInput = {
  oldCardId: CardId;
  newCardId: CardId;
};

export type WekRisk = {
  isSafe: boolean;
  threat?: object;
};

export type StatBreakdown = {
  monthly: number;
  weekly: number;
  daily: number;
};

export type MetricStat = {
  count: StatBreakdown;
  rateOfChange: StatBreakdown;
};

export type UniqueMetricStat = MetricStat & {
  uniqueCount: StatBreakdown;
};

export type CompanyMetrics = {
  reminderStat: UniqueMetricStat;
  followerStat: MetricStat;
  adClickStat: UniqueMetricStat;
  adLikeStat: MetricStat;
  pushStat: MetricStat;
  newCatalogPushStat: MetricStat;
  catalogPushStat: MetricStat;
  dateEpochGenerated: number;
};

export type CompanyAdMetrics = {
  reminderStat: UniqueMetricStat;
  adClickStat: UniqueMetricStat;
  adLikeStat: MetricStat;
  dateEpochGenerated: number;
};

export type CompanyCatalogMetrics = {
  pushStat: MetricStat;
  dateEpochGenerated: number;
};
