import styled from 'styled-components';

import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';

export const ButtonContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const AppErrorIcon = styled(HelpTwoToneIcon)`
  position: relative;
  font-size: 7rem;
  color: ${({ theme }) => theme.palette.error.main};
  opacity: 0.5;
`;