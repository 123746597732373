import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import AccountActionsMenu from './AccountActionsMenu';
import * as S from './SwayMeeAppBar.styled';

export interface SwayMeeAppBarProps {
  handleMenuClick?: () => void;
  title?: string;
}

export default function SwayMeeAppBar({
  handleMenuClick,
  title = '',
}: SwayMeeAppBarProps) {
  return (
    <S.AppBar>
      <S.Toolbar disableGutters>
        {handleMenuClick && (
          <S.IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenuClick}
          >
            <MenuIcon />
          </S.IconButton>
        )}

        {/* Company Logo*/}
        <S.Logo height="24px" width="31.65px" />

        {/* Business User Name*/}
        <S.Title variant="body1">{title}</S.Title>

        {/* Account Actions */}
        <AccountActionsMenu />
      </S.Toolbar>
    </S.AppBar>
  );
}
