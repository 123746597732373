import styled from 'styled-components';

import { mediaQuery } from '../../mixins';

import withTheme from '@material-ui/core/styles/withTheme';
import MuiDrawer from '@material-ui/core/Drawer';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';

const DRAW_WIDTH = 240;

/**
 * Styled Components for sliding Drawer
 */

export const Nav = withTheme(
  styled.nav`
    ${(props) => mediaQuery(props.theme.breakpoints.up('sm'))`
      width: ${DRAW_WIDTH}px;
      flex-shrink: 0;
    `}
  `
);

// TODO: Consider making Drawer -> SwipeableDrawer (https://material-ui.com/components/drawers/#swipeable)
export const Drawer = withTheme(
  styled(MuiDrawer)`
    ${(props) => mediaQuery(props.theme.breakpoints.up('sm'))`
      position: relative;
      width: ${DRAW_WIDTH}px;
      flex-shrink: 0;
    `}

    > .MuiDrawer-paper {
      width: ${DRAW_WIDTH}px;
      height: 100%;
      overflow: auto;
      display: table;
      ${(props) => mediaQuery(props.theme.breakpoints.up('sm'))`
        border: none;
      `}
    }
  `
);

export const DrawerContents = styled.div`
  overflow: auto;
`;

export const List = withTheme(
  styled(MuiList)`
    padding-top: 0px;
    padding-right: ${(props) => props.theme.spacing(1)}px;
    margin-left: 0px;
    margin-top: ${(props) => props.theme.spacing(2)}px;
    margin-bottom: ${(props) => props.theme.spacing(2)}px;
  `
);

export const ListItem = withTheme(
  styled(MuiListItem)`
    &:not(:first-child) {
      margin-top: ${(props) => props.theme.spacing(1)}px;
    }
    margin-bottom: ${(props) => props.theme.spacing(1)}px;
    border-bottom-right-radius: ${(props) => props.theme.spacing(4)}px;
    border-top-right-radius: ${(props) => props.theme.spacing(4)}px;
  `
);
