import { Types } from '../../api';
import { LocalStorageKey } from '../../utils/LocalStorage';

export const NAME = 'shared';

export enum ApiCallStatus {
  Idle = 'idle',
  Pending = 'pending',
  Success = 'success',
  Error = 'error',
}

export const LocalStorageKeys = {
  INITIAL_USER_PROFILE:
    'swaymee.business.InitialUserProfile' as LocalStorageKey<Types.UserProfile>,
  INITIAL_USER_TRACE:
    'swaymee.business.InitialUserTrace' as LocalStorageKey<Types.UserTrace>,
  INITIAL_USER_PROFILE_IMG:
    'swaymee.business.InitialUserProfileImageDataUrl' as LocalStorageKey<string>,
  INITIAL_USER_BILLING_ACCOUNT:
    'swaymee.business.InitialUserBillingAccount' as LocalStorageKey<Types.BillingAccount>,
};
