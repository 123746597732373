import { useCallback } from 'react';
import { gql, useQuery, useLazyQuery, QueryLazyOptions } from '@apollo/client';
import { CompanyMetrics } from '../../types';

const GET_COMPANY_METRICS = gql`
  query GetCompanyMetrics($companyId: String!) {
    companyMetrics(companyId: $companyId) {
      reminderStat {
        count {
          monthly
          weekly
          daily
        }
        uniqueCount {
          monthly
          weekly
          daily
        }
        rateOfChange {
          monthly
          weekly
          daily
        }
      }
      followerStat {
        count {
          monthly
          weekly
          daily
        }
        rateOfChange {
          monthly
          weekly
          daily
        }
      }
      adClickStat {
        count {
          monthly
          weekly
          daily
        }
        uniqueCount {
          monthly
          weekly
          daily
        }
        rateOfChange {
          monthly
          weekly
          daily
        }
      }
      adLikeStat {
        count {
          monthly
          weekly
          daily
        }
        rateOfChange {
          monthly
          weekly
          daily
        }
      }
      pushStat {
        count {
          monthly
          weekly
          daily
        }
        rateOfChange {
          monthly
          weekly
          daily
        }
      }
      catalogPushStat {
        count {
          monthly
          weekly
          daily
        }
        rateOfChange {
          monthly
          weekly
          daily
        }
      }
      newCatalogPushStat {
        count {
          monthly
          weekly
          daily
        }
        rateOfChange {
          monthly
          weekly
          daily
        }
      }
      generationDateEpoch
    }
  }
`;

type GetCompanyMetricsResponse = {
  companyMetrics: CompanyMetrics;
};
type GetCompanyMetricsOperationVariables = {
  companyId: string;
};
type GetCompanyCatalogRequest = GetCompanyMetricsOperationVariables;

export function useGetCompanyMetrics({ companyId }: GetCompanyCatalogRequest) {
  return useQuery<
    GetCompanyMetricsResponse,
    GetCompanyMetricsOperationVariables
  >(GET_COMPANY_METRICS, {
    variables: { companyId },
  });
}

export function useLazyGetCompanyMetrics() {
  const [execute, result] = useLazyQuery<
    GetCompanyMetricsResponse,
    GetCompanyMetricsOperationVariables
  >(GET_COMPANY_METRICS);

  const createVariables = useCallback<
    (
      a: GetCompanyMetricsOperationVariables
    ) => QueryLazyOptions<GetCompanyMetricsOperationVariables>
  >(
    ({ companyId }) => ({
      variables: { companyId },
    }),
    []
  );

  return {
    execute,
    createVariables,
    ...result,
  };
}
