import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

const DELETE_DRAFT_AD_MUTATION = gql`
  mutation DeleteDraftAd($adId: String!, $companyID: String) {
    deleteDraftAd(adId: $adId, companyID: $companyID) {
      success
    }
  }
`;

type DeleteDraftAdResponse = {
  deleteDraftAd: { success: boolean };
};

type DeleteDraftAdOperationVariables = {
  adId: string;
};

export function useDeleteDraftAd() {
  const [execute, result] = useMutation<
    DeleteDraftAdResponse,
    DeleteDraftAdOperationVariables
  >(DELETE_DRAFT_AD_MUTATION);

  const createVariables = useCallback<(adId: string) => any>(
    (adId) => ({
      variables: { adId },
    }),
    []
  );

  return {
    execute,
    createVariables,
    ...result,
  };
}
