import firebase, { FirebaseError } from 'firebase/app';
import { LogInErrorCodes } from './constants';

export const getCurrentUser = () => firebase.auth().currentUser;

const createFirebaseError = (code: string, message: string): FirebaseError => ({
  code,
  message,
  name: 'FirebaseError',
});

export interface FirebaseAuthResponse<T> {
  payload: T | null;
  error: FirebaseError | null;
}
export interface FirebaseAuthVoidResponse {
  error: FirebaseError | null;
}

const handleCatch = (error: FirebaseError) => ({
  error,
  payload: null,
});

const getUidOrUnknownError = (
  userCredential: firebase.auth.UserCredential
): FirebaseAuthResponse<firebase.User> => {
  if (userCredential.user) {
    reloadUser();
    return { payload: userCredential.user, error: null };
  } else {
    return {
      payload: null,
      error: createFirebaseError(
        LogInErrorCodes.Unknown,
        'Unable to log in at the moment. Try again.'
      ),
    };
  }
};

export async function getIdToken() {
  return await getCurrentUser()!.getIdToken();
}

export async function reloadUser() {
  return await getCurrentUser()!.reload();
}

export const FirebaseAuth = {
  async applyActionCode(code: string): Promise<FirebaseAuthVoidResponse> {
    return await firebase
      .auth()
      .applyActionCode(code)
      .then(async () => {
        // IDK why it works this way but https://github.com/flutter/flutter/issues/20390
        await getCurrentUser();
        await reloadUser();
        await getCurrentUser();
      })
      .then(() => ({ error: null }))
      .catch(handleCatch);
  },

  async confirmPasswordReset(code: string, newPassword: string) {
    return await firebase
      .auth()
      .verifyPasswordResetCode(code)
      .then(() => firebase.auth().confirmPasswordReset(code, newPassword))
      .then(() => ({ error: null }))
      .catch(handleCatch);
  },

  async sendPasswordReset(
    email: string,
    url: string
  ): Promise<FirebaseAuthVoidResponse> {
    return await firebase
      .auth()
      .sendPasswordResetEmail(email, { handleCodeInApp: true, url })
      .then(() => ({ error: null }))
      .catch(handleCatch);
  },

  async sendEmailVerification(url: string): Promise<FirebaseAuthVoidResponse> {
    return await getCurrentUser()!!
      .sendEmailVerification({
        url,
        handleCodeInApp: true,
      })
      .then(() => ({ error: null }))
      .catch(handleCatch);
  },

  async createUserWithEmailPassword(
    emailAddress: string,
    password: string
  ): Promise<FirebaseAuthResponse<firebase.User>> {
    return await firebase
      .auth()
      .createUserWithEmailAndPassword(emailAddress, password)
      .then(getUidOrUnknownError)
      .catch(handleCatch);
  },

  async logInWithEmailPassword(
    emailAddress: string,
    password: string
  ): Promise<FirebaseAuthResponse<firebase.User>> {
    return await firebase
      .auth()
      .signInWithEmailAndPassword(emailAddress, password)
      .then(getUidOrUnknownError)
      .catch(handleCatch);
  },

  async logout(): Promise<FirebaseAuthVoidResponse> {
    return await firebase
      .auth()
      .signOut()
      .then(async () => {
        // IDK why it works this way but https://github.com/flutter/flutter/issues/20390
        await getCurrentUser();
      })
      .then(() => ({ error: null }))
      .catch(handleCatch);
  },

  /**
   *
   * Important: this is a security-sensitive operation that requires the user to have recently signed in.
   * If this requirement isn't met, ask the user to authenticate again and then call firebase.User.reauthenticateWithCredential.
   */
  async deleteAccount(): Promise<FirebaseAuthVoidResponse> {
    return await getCurrentUser()!!
      .delete()
      .then(async () => {
        // IDK why it works this way but https://github.com/flutter/flutter/issues/20390
        await getCurrentUser();
      })
      .then(() => ({ error: null }))
      .catch(handleCatch);
  },

  async reauthenticateCurrentUserWithPassword(
    password: string
  ): Promise<FirebaseAuthVoidResponse> {
    const currentUser = firebase.auth().currentUser!;
    const credential = firebase.auth.EmailAuthProvider.credential(
      currentUser.email!,
      password
    );

    try {
      return await currentUser
        .reauthenticateWithCredential(credential)
        .then(() => {
          return { error: null };
        })
        .catch((error) => {
          return {
            error,
          };
        });
    } catch (err) {
      return {
        error: err,
      };
    }
  },

  attachAuthStateChangeListener(callBack: (arg: boolean) => void) {
    return firebase.auth().onAuthStateChanged((user) => {
      callBack(user !== null);
    });
  },

  async getAuthRedirectResults(): Promise<
    FirebaseAuthResponse<{ uid: string }>
  > {
    return await firebase
      .auth()
      .getRedirectResult()
      .then(getUidOrUnknownError)
      .catch(handleCatch);
  },
};
