import { useCallback } from 'react';
import { gql, useQuery, useLazyQuery, QueryLazyOptions } from '@apollo/client';
import { ALL_AD_FIELDS } from '../../constants';
import { Catalog } from '../../types';

export const GET_CATALOGS_ARCHIVED_ADS_QUERY = gql`
    query GetCatalogsArchivedAdsFeed($catalogIds: [String!], $adFirst: Int, $companyID: String){
      catalogs(catalogIds: $catalogIds, companyID: $companyID) {
        catalogId
        displayName
        archivedAds(first: $adFirst) {
          edges {
            cursor
            node {
              ${ALL_AD_FIELDS}
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    }
  `;

type GetCatalogsArchivedAdsResponse = {
  catalogs: (Catalog | null)[];
};
type GetCatalogsArchivedAdsOperationVariables = {
  catalogIds?: string[];
  adFirst?: number;
  adEndCursor?: string;
  companyID: string;
};
type GetCatalogsArchivedAdsRequest = GetCatalogsArchivedAdsOperationVariables;

export function useGetCatalogsArchivedAds({
  catalogIds,
  adFirst,
  companyID,
}: GetCatalogsArchivedAdsRequest) {
  return useQuery<
    GetCatalogsArchivedAdsResponse,
    GetCatalogsArchivedAdsOperationVariables
  >(GET_CATALOGS_ARCHIVED_ADS_QUERY, {
    variables: { adFirst, catalogIds, companyID },
  });
}

export function useLazyGetCatalogsArchivedAds() {
  const [execute, result] = useLazyQuery<
    GetCatalogsArchivedAdsResponse,
    GetCatalogsArchivedAdsOperationVariables
  >(GET_CATALOGS_ARCHIVED_ADS_QUERY);

  const createVariables = useCallback<
    (
      a: GetCatalogsArchivedAdsOperationVariables
    ) => QueryLazyOptions<GetCatalogsArchivedAdsOperationVariables>
  >(
    ({ catalogIds, adFirst, companyID }) => ({
      variables: { adFirst, catalogIds, companyID },
    }),
    []
  );

  return {
    execute,
    createVariables,
    ...result,
  };
}
