import { ApiCallStatus } from '../shared/shared.constants';
import { billingApi } from '../../api';

export const NAME = 'billing';

export enum BillingApiRequestTypes {
  CreateBillingAccount = 'createBillingAccount',
  AddCard = 'addCard',
  RemoveCard = 'removeCard',
  EditCard = 'editCard',
  ReplaceCard = 'replaceCard',
  SetPrimaryCardId = 'setPrimaryCardId',
  GetTransactions = 'getTransactions',
}

export type BillingApiRequests = {
  [BillingApiRequestTypes.CreateBillingAccount]: {
    status: ApiCallStatus;
    billingAccount?: billingApi.BillingAccount;
    error?: any;
  };
  [BillingApiRequestTypes.AddCard]: {
    status: ApiCallStatus;
    error?: any;
  };
  [BillingApiRequestTypes.RemoveCard]: {
    status: ApiCallStatus;
    error?: any;
  };
  [BillingApiRequestTypes.EditCard]: {
    status: ApiCallStatus;
    error?: any;
  };
  [BillingApiRequestTypes.ReplaceCard]: {
    status: ApiCallStatus;
    error?: any;
  };
  [BillingApiRequestTypes.SetPrimaryCardId]: {
    status: ApiCallStatus;
    error?: any;
  };
};

export type BillingStatusUpdates<P extends keyof BillingApiRequests> = {
  requestType: P;
  update: BillingApiRequests[P];
};

export function createUpdate<P extends keyof BillingApiRequests>(
  requestType: P,
  update: BillingApiRequests[P]
): BillingStatusUpdates<P> {
  return {
    requestType,
    update,
  };
}
