import {
  NAME,
  UserTraceApiRequests,
  UserTraceStatusUpdates,
} from './userTracing.constants';
import { ActionType, createAction } from 'deox';
import { Types } from '../../api';
import { UserAgentInformation } from '../../utils/UserInfo';

export const fetchUserTrace = createAction(`${NAME}/fetchUserTraceRequest`);
export const fetchUserTraceSuccess = createAction(
  `${NAME}/fetchUserTraceSuccess`,
  (resolve) => (payload: Types.UserTraceResult) => resolve(payload)
);
export const fetchUserTraceFailure = createAction(
  `${NAME}/fetchUserTracingFailure`,
  (resolve) => (payload: any) => resolve(payload)
);
export function isFetchUserTraceSuccess(
  arg: ActionType<typeof fetchUserTraceFailure | typeof fetchUserTraceSuccess>
): arg is ActionType<typeof fetchUserTraceSuccess> {
  return arg.type === fetchUserTraceSuccess.type;
}

export const createUserTrace = createAction(`${NAME}/createUserTrace`);
export const createUserTraceSuccess = createAction(
  `${NAME}/createUserTraceSuccess`,
  (resolve) => (payload: Types.UserTrace) => resolve(payload)
);
export const createUserTraceFailure = createAction(
  `${NAME}/createUserTraceFailure`,
  (resolve) => (payload: any) => resolve(payload)
);

export const updatePageVisited = createAction(
  `${NAME}/updatePageVisited`,
  (resolve) => (payload: Types.PageVisited) => resolve(payload)
);

export const updateHTTPInfo = createAction(
  `${NAME}/updateHTTPInfo`,
  (resolve) => (payload: UserAgentInformation) => resolve(payload)
);

export const updateUserTrace = createAction(
  `${NAME}/updateUserTrace`,
  (resolve) =>
    (payload: Partial<Pick<Types.UserTrace, 'pagesVisitedStatistics'>>) =>
      resolve(payload)
);

export const setApiStatus = createAction(
  `${NAME}/setApiStatus`,
  (resolve) =>
    <T extends keyof UserTraceApiRequests>(
      payload: UserTraceStatusUpdates<T>
    ) =>
      resolve(payload)
);
