export enum CreateAccountErrorCodes {
  // Thrown if there already exists an account with the given email address
  EmailInUse = 'auth/email-already-in-use',
  // Thrown if the email address is not valid.
  InvalidEmail = 'auth/invalid-email',
  // Thrown if email/password accounts are not enabled. Enable email/password
  // accounts in the Firebase Console, under the Auth tab.
  NotAllowed = 'auth/operation-not-allowed',
  // Thrown if the password is not strong enough
  WeakPassword = 'auth/weak-password',
}

export enum LogInErrorCodes {
  // Thrown if the email address is not valid.
  InvalidEmail = 'auth/invalid-email',
  // Thrown if the user corresponding to the given email has been disabled.
  UserDisabled = 'auth/user-disabled',
  // Thrown if there is no user corresponding to the given email.
  UserNotFound = 'auth/user-not-found',
  // Thrown if the password is invalid for the given email, or
  // the account corresponding to the email does not have a password set.
  WrongPassword = 'auth/wrong-password',
  // This shouldn't happen
  Unknown = 'auth/unknown',
}

export enum SignInWithRedirectErrorCodes {
  // Thrown if authDomain configuration is not provided when calling firebase.initializeApp().
  // Check Firebase Console for instructions on determining and passing that field.
  AuthDomainConfigRequired = 'auth/auth-domain-config-required',
  // Thrown if this operation is not supported in the environment your application is running on.
  // "location.protocol" must be http or https.
  OperationNotSupportedInThisEnv = 'auth/operation-not-supported-in-this-environment',
  // Thrown if the app domain is not authorized for OAuth operations for your Firebase project.
  // Edit the list of authorized domains from the Firebase console.
  UnauthorizedDomain = 'auth/unauthorized-domain',
}

export enum ResetPasswordErrorCodes {
  // Thrown if the email address is not valid.
  InvalidEmail = 'auth/invalid-email',
  // A continue URL must be provided in the request.
  MissingContinueUri = 'auth/missing-continue-uri',
  // The continue URL provided in the request is invalid.
  InvalidContinueUri = 'auth/invalid-continue-uri',
  // The domain of the continue URL is not whitelisted.
  // Whitelist the domain in the Firebase console.
  UnauthorizedContinueUri = 'auth/unauthorized-continue-uri',
  // Thrown if there is no user corresponding to the email address.
  UserNotFound = 'auth/user-not-found',
}

export enum ConfirmPasswordResetErrorCodes {
  // Thrown if the password reset code has expired.
  ExpiredActionCode = 'auth/expired-action-code',
  // Thrown if the password reset code is invalid. This can happen
  // if the code is malformed or has already been used.
  InvalidActionCode = 'auth/invalid-action-code',
  // Thrown if the user corresponding to the given password reset code has been disabled.
  UserDisabled = 'auth/user-disabled',
  // Thrown if there is no user corresponding to the password reset code.
  // This may have happened if the user was deleted between when the code
  // was issued and when this method was called.
  UserNotFound = 'auth/user-not-found',
  // Thrown if the password is not strong enough
  WeakPassword = 'auth/weak-password',
}

export enum ApplyActionCodeErrorCodes {
  // Thrown if the password reset code has expired.
  ExpiredActionCode = 'auth/expired-action-code',
  // Thrown if the password reset code is invalid. This can happen
  // if the code is malformed or has already been used.
  InvalidActionCode = 'auth/invalid-action-code',
  // Thrown if the user corresponding to the given password reset code has been disabled.
  UserDisabled = 'auth/user-disabled',
  // Thrown if there is no user corresponding to the password reset code.
  // This may have happened if the user was deleted between when the code
  // was issued and when this method was called.
  UserNotFound = 'auth/user-not-found',
}

export enum SecuritySensitiveActionErrorCodes {
  // Thrown if the user's last sign-in time does not meet the security threshold.
  // Use firebase.User.reauthenticateWithCredential to resolve.
  // This does not apply if the user is anonymous.
  RecentLoginRequired = 'auth/requires-recent-login',
}
