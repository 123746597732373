import styled from 'styled-components';
import MuiDialog from '@material-ui/core/Dialog/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDivider from '@material-ui/core/Divider';
import MuiButton from '@material-ui/core/Button';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiAvatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField/TextField';
import Typography from '@material-ui/core/Typography';
import SMProgressButton from '../../common/ProgressButton/ProgressButton';
import { rgba2hex } from '../../common/utils';

export const Dialog = styled(MuiDialog)`
  padding: ${({ theme }) => theme.spacing(1)}px;
`;

export const DialogTitle = styled(MuiDialogTitle)`
  padding: ${({ theme }) => theme.spacing(1, 3)};
`;

export const DialogContent = styled(MuiDialogContent)`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const DialogActions = styled(MuiDialogActions)`
  padding: ${({ theme }) => theme.spacing(2)}px;
  button:last-child {
    margin-left: ${({ theme }) => theme.spacing(2)}px;
  }
`;

export const Divider = styled(MuiDivider)``;

export const Button = styled(MuiButton)`
  padding: ${({ theme }) => theme.spacing(1, 2)};
`;

export const ProgressButton = styled(SMProgressButton)`
  padding: ${({ theme }) => theme.spacing(1, 2)};
`;

export const IconButton = styled(MuiIconButton)`
  font-size: inherit;
`;

const Avatar = styled(MuiAvatar)`
  margin: ${({ theme }) => theme.spacing(1)}px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

export const AuthAvatar = Avatar;

interface AuthFormProps {
  themeSpacingNumber?: number;
}

const Form = styled.form<AuthFormProps>`
  width: 100%; // Fix IE 11 issue.
  margin-top: ${({ theme, themeSpacingNumber = 1 }) =>
    theme.spacing(themeSpacingNumber)}px;
`;

export const AuthForm = Form;

export const FormMessageContent = styled.div`
  padding: ${({ theme }) => theme.spacing(2, 0)};
  margin: ${({ theme }) => theme.spacing(0, 1)};
`;

export const AuthPaper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PrefilledTextField = styled(TextField)`
  .MuiInputBase-root {
    color: ${({ theme }) => rgba2hex(theme.palette.text.primary)};
  }
  [class*='Outline-root'] {
    border-style: none;
  }
`;

export const ErrorMessage = styled(Typography)`
  padding: ${({ theme }) => theme.spacing(2, 0)};
  margin: ${({ theme }) => theme.spacing(0, 1)};
`;

export const FooterLink = styled(Typography)`
  padding: ${({ theme }) => theme.spacing(1, 0)};
  margin: ${({ theme }) => theme.spacing(0, 1)};
  color: ${({ theme }) => theme.palette.text.primary};
`;
